import { NgModule } from '@angular/core';
import { JsonFormatterPipe } from './json-formatter.pipe';

@NgModule({
    declarations: [
      JsonFormatterPipe
    ],
    imports: [],
    exports: [
      JsonFormatterPipe
    ]
})
export class PipesModule {}