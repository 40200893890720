import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-store-image-galary',
  templateUrl: './store-image-galary.component.html',
  styleUrls: ['./store-image-galary.component.css']
})
export class StoreImageGalaryComponent implements OnInit {
  
  storeImage = [];

  constructor(private appService: AppService, 
    private http: HttpClient,
    private router: ActivatedRoute,
    private route : Router) { }

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength : 5,
      processing: true,
      lengthMenu : [5,10,20,50,100,500]
    };
    this.getStoreImage();
  }
  getStoreImage(){
    
    const endpoint = environment.baseURL+'store/read-image.php'; 
    return this.http
            .get(endpoint)
            .subscribe((res:any) => {
              this.storeImage = (res.records)?res.records:[];
              this.dtTrigger.next();
            }, (error) => {
                this.appService.showError('Something went wrong.');
            });
   
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
