import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../shared/services/app.service';
import { timer } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WindowRef } from '../shared/services/windowRef.service';

@Component({
    selector: 'app-my-cart-page',
    providers: [WindowRef],
    templateUrl: './my-cart.component.html'
})
export class MyCartComponent implements OnInit {
    addressForm: FormGroup;

    userId = '';
    cart = [];
    cartItems: any = [];
    store: any = {};
    cartDetails = [];
    showCancelOrder = false;
    timeout = setTimeout(() => { }, 3600 * 1000);
    displayCancelOrder = false;
    timeLeft = 60;
    interval;
    subscribeTimer: any;
    showCancelButton = false;
    orderId;
    isError = false;
    qty;
    totalPrice = 0;
    totalPriceWithTaxces = 0;
    taxes = 0;
    isDeliverable = 0;
    customerAddress = '';
    deliveryAddress = null;
    email;
    update;
    selectedAddress;
    name;
    paymentOption = '';
    numberOfCartItem = 0;

    constructor(private http: HttpClient, private appService: AppService, private router: Router,
                public matDialog: MatDialog, private formBuilder: FormBuilder,private winRef: WindowRef ) {
        this.userId = this.appService.getDInfoId();
        this.email = this.appService.getEmail();
        this.name = this.appService.getUserName();
        this.appService.numberOfCartItem.subscribe(numberOfCartItem => {
            this.numberOfCartItem = numberOfCartItem;
          });
    }

    ngOnInit() {
        this.getList();
        this.appService.getCartItems().subscribe((res: any) => {
            this.cartItems = res;
        });
        this.getDeliveryAddress();
        this.addressForm = this.formBuilder.group({
            id: '',
            email: [''],
            address: ['', Validators.required],
            landmark: ['', Validators.required],
            country: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            zipcode: ['', Validators.required]
        });
    }

    getList() {
        this.http.get(environment.baseURL+'cart/read.php?userId=' + this.userId).subscribe((res: any) => {
            this.cartItems = [];
            if (res.records) {
                this.cart = res.records;
                this.cartDetails = res.records[0];
                this.cartItems = res.records[0].products;
                this.cartItems = JSON.parse(this.cart[0].products);
                this.getStore(res.records[0].store_id);
                this.appService.setCartItems(this.cartItems);
                this.getTotalPrice();
            }
        });
    }

    getDeliveryAddress() {
        this.http.get(environment.baseURL+'customer_address/read.php?email=' + this.appService.getEmail()).subscribe((res: any) => {
            if (res.records) {
                this.deliveryAddress = res.records;
            }
        });
    }

    placeOrder(razorpay_payment_id='') {
        if (this.cart.length > 0) {
            this.cart[0].products = JSON.stringify(JSON.stringify(this.cartItems));
            this.cart[0].isDeliverable = this.isDeliverable;
            this.cart[0].customerAddress = this.customerAddress;
            this.cart[0].payment_id = (razorpay_payment_id)?razorpay_payment_id:'';
            this.cart[0].payment_status =(razorpay_payment_id)?1:0;
            this.cart[0].payment_option = this.paymentOption;
            if (this.isDeliverable === 1 && !this.customerAddress) {
                this.appService.showError('Please select at least one address for delivery');
                return;
            }
            this.http.post(environment.baseURL+'order/create.php', this.cart[0]).subscribe((res: any) => {
                if (res.id) {
                    this.orderId = res.id;
                }
                if (res.message && res.message === 'Order has been placed successfully') {
                    this.appService.showSuccess(res.message);
                    this.http.get(environment.baseURL+'cart/delete.php?userId=' + this.userId).subscribe((resp: any) => {
                        // this.startTimer();
                        this.getCartItems();
                        this.appService.setCartItems([]);
                        this.router.navigate(['my-orders']);
                    });
                }
            });
        }
    }

    startTimer() {
        this.showCancelButton = true;
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
                if (this.timeLeft === 0) {
                    this.router.navigate(['my-orders']);
                }
            } else {
                this.timeLeft = 60;
            }
        }, 1000);
    }

    pauseTimer() {
        clearInterval(this.interval);
        this.getList();
    }

    cancelOrder() {
        this.pauseTimer();
        let url = '';
        url = environment.baseURL+'order/update.php';
        this.http
            .post(url, { id: this.orderId, status: 'cancel_order_by_customer', value: 'cancelled' })
            .subscribe((res) => {
                this.appService.showSuccess('Order has been cancelled');
                this.getList();
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }

    getStore(storeId) {
        this.http.get(environment.baseURL + 'store/read.php?storeId=' + storeId)
            .subscribe((res: any) => {
                this.store = res.records[0];
            });
    }

    productQuantity(product) {
        if (isNaN(product.qty) || product.qty < 1 || product.qty > 10) {
            this.isError = true;
            this.appService.showError('Product quantity should be between 1 to 10 and only digits are allowed.');
            return false;
        } else {
            this.isError = false;
        }
        this.cartItems.filter((item, index) => {
            if (item.id === product.id) {
                item.qty = product.qty;
                if (product && product.length) {
                    this.http.post(environment.baseURL + 'cart/delete.php?userId=' + this.userId, product.id)
                        .subscribe((res) => {
                            this.appService.showSuccess('Product quantity has been updated successfully.');
                        });
                } else {
                    this.updateCartData(this.cartItems, this.cartDetails, 'Product quantity has been updated successfully.');

                }
            }
        });
    }

    deleteItemFromCart(product) {
        this.cartItems.filter((item, index) => {
            if (item.id === product.id) {
                this.cartItems.splice(index, 1);
                this.updateCartData(this.cartItems, this.cartDetails, 'Product has been deleted from your cart successfully.');
            }
        });
        this.getTotalPrice();
    }

    updateCartData(product, cart, msg) {
        let items = this.cartItems;
        if (!this.cartItems.length) {
            items = '';
        }
        const data = { id: cart.id, store_id: cart.store_id, user_id: this.appService.getDInfoId(), products: JSON.stringify(items) };
        let url = '';
        if (this.cartItems.length) {
            url = 'cart/update.php';
        } else {
            url = 'cart/delete.php?userId=' + this.userId;
        }
        this.http.post(environment.baseURL + url, data)
            .subscribe((res) => {
                this.appService.showSuccess(msg);
                this.appService.setCartItems(this.cartItems);
                this.getCartItems();
            });
    }

    calculatePrice(item) {
        this.getTotalPrice();
        return Number(item.qty) * Number(item.amount);
    }
    getTotalPrice() {
        // tslint:disable-next-line:prefer-for-of
        this.totalPrice = 0;
        for (let i = 0; i < this.cartItems.length; i++) {
            this.totalPrice += Number(this.cartItems[i].qty) * Number(this.cartItems[i].amount);
        }
        this.totalPriceWithTaxces = Number((this.totalPrice + (this.totalPrice*8.25)/100).toFixed(2));
        this.taxes = Number(((this.totalPrice*8.25)/100).toFixed(2));
        return this.totalPrice.toFixed(2);

    }

    addEditAddress(address) {
        this.selectedAddress = address;
        if (address) {
            this.update = 1;
            this.addressForm.controls.address.setValue(address.address);
            this.addressForm.controls.landmark.setValue(address.landmark);
            this.addressForm.controls.zipcode.setValue(address.zipcode);
            this.addressForm.controls.country.setValue(address.country);
            this.addressForm.controls.state.setValue(address.state);
            this.addressForm.controls.city.setValue(address.city);
            this.addressForm.controls.firstName.setValue(address.first_name);
            this.addressForm.controls.lastName.setValue(address.last_name);
            this.addressForm.controls.mobile.setValue(address.mobile);
        } else {
            this.update = 0;
            this.addressForm.reset();
        }
    }

    updateAddress() {
        this.addressForm.patchValue({
            id:this.selectedAddress.id
        });
        // stop here if form is invalid
        if (this.addressForm.invalid) {
            return;
        }
        this.http.post(environment.baseURL + 'customer_address/update.php', this.addressForm.value)
        .subscribe((res: any) => {
            this.appService.showSuccess('Address updated successfully');
            this.getDeliveryAddress();
        }, (error) => {
            this.appService.showError('Address saving failed. ' + error.error.message);
        });
    }

    createAddress() {
        this.addressForm.patchValue({
            email: this.email
        });
        // stop here if form is invalid
        if (this.addressForm.invalid) {
            return;
        }
        this.http.post(environment.baseURL + 'customer_address/create.php', this.addressForm.value)
            .subscribe((res: any) => {
                this.appService.showSuccess('Address saved successfully');
                this.getDeliveryAddress();
            }, (error) => {
                this.appService.showError('Address saving failed. ' + error.error.message);
            });
    }

    deleteAddress(addressId) {
        if (confirm("Are you sure to delete the address?")) {
            this.http.post(environment.baseURL + 'customer_address/delete.php', { id: addressId })
                .subscribe((res: any) => {
                    this.appService.showSuccess('Address deleted successfully');
                    this.getDeliveryAddress();
                }, (error) => {
                    this.appService.showError('Address deletion failed. ' + error.error.message);
                });
        }
    }
        payWithRazor(){
            let amount = Number(this.totalPriceWithTaxces*100).toFixed(2); 
            let options:any = {
                "key": "rzp_test_CFj2TRE7YRnAQh",
                "amount": amount,//1000,
                "currency" : 'INR',
                "name": "DinfoKit",
                "description": "Desi-Deals-Info-Kit",
                "image": "/assets/img/logo.png",
                "modal": {
                  "escape": false
                }, 
                "prefill": {
                  "name": this.name, //"Ramesh",
                //   "contact": "9032785653",
                  "email": this.email,//"avancharamesh530@gmail.com",
                },
                "notes": {
                  "address": this.deliveryAddress//"BUs Stop"
                },
                "theme": {
                  "color": "#6fbc29"
                }
              };
              options.handler = ((response) => {
                  this.placeOrder(response.razorpay_payment_id);
                //   options['payment_response_id'] = response.razorpay_payment_id;
                //   console.log(response); 
                //   this.paymentService.payWithRazor({cart: finalObj, payment: options});
              });
              options.modal.ondismiss = (() => {
                //   this.loginService.SetLoader = false;
              });
              let rzp = new this.winRef.nativeWindow.Razorpay(options);
              rzp.open();
          } 

          paymentOptionSet(option:string){
              this.paymentOption = option;
          }
          orderPlace(){  
              if(this.paymentOption === 'online'){
                  this.payWithRazor();
                  this.appService.numberOfCartItem.next(this.numberOfCartItem);
              }else{
                  this.placeOrder();           
                  this.appService.numberOfCartItem.next(this.numberOfCartItem);
              }
          }

          getCartItems() {
            this.http.get(environment.baseURL+'cart/read.php?userId=' + this.userId).subscribe((res: any) => {
              if (res && res.records && res.records[0] && res.records[0].products) {
                const cartdata = JSON.parse(res.records[0].products);
                this.appService.setCartItems(cartdata);
                this.cartDetails = res.records[0];
                this.numberOfCartItem = 0;
                for(let cartDt of cartdata){
                  this.numberOfCartItem = this.numberOfCartItem + cartDt.qty;
                  
                }
                this.appService.numberOfCartItem.next(this.numberOfCartItem);
              }
            });
          }
}
