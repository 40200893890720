import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-add-edit-child-category',
    templateUrl: './add-edit-child-category.component.html'
})
export class AddEditChildCategoryComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name'];
    dataSource: any;
    subCategories = [];
    id = '';
    title = 'Add Child Category';
    childCategoryForm: FormGroup;
    submitted = false;

    constructor(private appService: AppService, private http: HttpClient, private router: ActivatedRoute, public dialog: MatDialog,
                private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.buildFormGroup();
        this.getSubCategory();
        this.getChildCategory();

    }
    get f() { return this.childCategoryForm.controls; }

    buildFormGroup() {
        this.childCategoryForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            sub_cat_id: ['', Validators.required]
        });
    }
    setChildCategoryData(data) {
        this.childCategoryForm.controls.id.setValue(data.id);
        this.childCategoryForm.controls.name.setValue(data.name);
        this.childCategoryForm.controls.sub_cat_id.setValue(data.sub_cat_id);
    }

    getSubCategory() {
        this.http.get(environment.baseURL+'sub_category/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.subCategories = res.records;
                }
            });
    }

    getChildCategory() {
        this.router.paramMap.subscribe(paramMap => {
            this.id = paramMap.get('id');
            if (this.id) {
                this.title = 'Edit Child Category';
                this.http.get(environment.baseURL + 'child_category/read.php?id=' + this.id)
                    .subscribe((res: any) => {
                        if (res && res.records) {
                            this.setChildCategoryData(res.records[0]);
                        }
                });
            }
        });
    }
    onSubmit() {
        let url = '';
        let message = 'Child category has been created successfully';
        if (this.id) {
            url = environment.baseURL+'child_category/update.php';
        } else {
            url = environment.baseURL+'child_category/create.php';
            message = 'Child category has been updated successfully';
        }
        this.submitted = true;
        if (this.childCategoryForm.invalid) {
            return;
        }

        this.http
            .post(url, this.childCategoryForm.value)
            .subscribe((res) => {
                this.appService.showSuccess(message);
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }
}
