import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { AppService } from '../shared/services/app.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacebookService, InitParams } from 'ngx-fb';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { debug } from 'console';


@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DetailPageComponent implements OnInit {
  
  subCategories = [];
  childCategories = [];
  productForm: FormGroup;
  submitted = false;
  numberOfCartItem=0;

  storeImageGalarayData = [];
  player: YT.Player;
  storeData: any;
  productData: any = [];
  subCategoryData: any = [];
  childCategoryData: any = [];
  galleryCards = [];
  rightAds = [];
  bottomAds = [];
  headerAds = [];
  subscription: Subscription;
  dealsId = '';
  dealsProduct = [];
  userId = '';
  userType = '';
  cartDetails: any = [];
  photos = [];
  city = '';
  selectedSubCatId = '';
  selectedChild = '';
  products = [];
  selectedProduct: any = { qty: 0, image: '' };
  productImage = '';
  isError = false;
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 3000 };
  showDetails = false;
  catId: 0;
  storeId;
  window: any;
  InitParams: InitParams = {
    appId: 'xxxxxxx',
    xfbml: true,
    version: 'v3.3'
  };

  constructor(private fbService: FacebookService, private router: ActivatedRoute, private http: HttpClient,
              private appService: AppService, private route: Router,private formBuilder: FormBuilder) {
    this.fbService.init(this.InitParams);
    this.router.paramMap.subscribe(paramMap => {

      const storeId = paramMap.get('id');
      this.storeId = storeId;
      if (storeId) {
        this.getProducts(storeId);
        this.getAds(storeId);
        this.getStore(storeId);
        this.getStoreImageGalary(storeId);
        
      }
    });
    this.productForm = this.formBuilder.group({
      id: [''],
      store_id: ['', Validators.required],
      name: ['', Validators.required],
      quantity: ['', Validators.required],
      amount: ['', Validators.required],
      sub_cat_id: ['', Validators.required],
      child_cat_id: ['', Validators.required],
      is_available: ['', Validators.required],
      description: ['', Validators.required],
      details: ['', Validators.required],
      video_type: [''],
      video_url: [''],
      promotional: [''],
  });
  this.appService.numberOfCartItem.subscribe(numberOfCartItem => {
    this.numberOfCartItem = numberOfCartItem;
  });
  }

  ngOnInit() {
    
    try {
      this.city = this.appService.city;
      this.subscription = this.appService.getCity().subscribe(city => {
        this.city = city.name;
      });
      this.appService.getCartItems().subscribe((res: any) => {
        this.cartDetails.products = res;
      });
      this.userId = this.appService.getDInfoId();
      this.userType = this.appService.getUserType();
      console.log('userType ', this.userType);
      this.getCartItems();

      
      // this.window.FB.XFBML.parse();
    } catch (e) {
      console.log('error ', e);
    }
  }

  getCartItems() {

    this.http.get(environment.baseURL+'cart/read.php?userId=' + this.userId).subscribe((res: any) => {
      if (res && res.records && res.records[0] && res.records[0].products) {
        const cartdata = JSON.parse(res.records[0].products);
        this.appService.setCartItems(cartdata);
        this.cartDetails = res.records[0];
        this.cartDetails.products = JSON.parse(this.cartDetails.products);
        this.numberOfCartItem = 0;
        for(let cartDt of cartdata){
          this.numberOfCartItem = this.numberOfCartItem + cartDt.qty;
          
        }
        this.appService.numberOfCartItem.next(this.numberOfCartItem);
      }
    });
  }

  getStore(storeId) {
    this.http.get(environment.baseURL + 'store/read.php?storeId=' + storeId)
      .subscribe((res: any) => {
        this.storeData = res.records[0];
        this.catId = this.storeData.catId;
      });
  }
  getStoreImageGalary(storeId){
    this.http.get(environment.baseURL+'store/read-image.php?store_id='+storeId)
    .subscribe((res: any) => {
      this.storeImageGalarayData = (res.records)?res.records:[];
    });
  }

  async getProducts(storeId) {
    await this.http.get(environment.baseURL + 'product/read.php?storeId=' + storeId)
      .subscribe((res: any) => {
        const arrr = [];
        this.productData = res.records;    
        // console.log('products =>',this.productData);
        this.productData.map(product => {
          if (!this.subCategoryData.includes(product.sub_cat_name)) {
            this.subCategoryData.push(product.sub_cat_name);
            arrr.push([product.sub_cat_name, product.child_cat_name]);
          }
        });
        if (this.subCategoryData.length > 0) {
          this.selectedSubCatId = this.subCategoryData[0];
          this.getChild();
        }
        this.fbService.init(this.InitParams);
      });
      // console.log('carts data=>',this.subCategoryData)
  }

  getChild() {
    this.childCategoryData = [];
    this.productData.map(product => {
      if (this.selectedSubCatId === product.sub_cat_name) {
        if (!this.childCategoryData.includes(product.child_cat_name)) {
          this.childCategoryData.push(product.child_cat_name);
        }
      }
    });
    this.selectedChild = this.childCategoryData[0];
  }
  getChildWithNewProduct() {
    this.childCategoryData = [];
    this.productData.map(product => {
      let currentDate = new Date();
      let timeOneWeak = currentDate.setDate(currentDate.getDate() - 7);
      let productDate = new Date(product.endDate).getTime();
      if (productDate > timeOneWeak) {
        if (!this.childCategoryData.includes(product.child_cat_name)) {
          this.childCategoryData.push(product.child_cat_name);
        }
      }
    });
    this.selectedChild = this.childCategoryData[0];
  }

  

  addProduct(product) {
    this.fbService.init(this.InitParams);
    this.isError = false;
    this.getPhotos(product.id);
    this.selectedProduct = product;
    this.selectedProduct.qty = 0;

    if (this.cartDetails && this.cartDetails.store_id == this.storeData.id && this.cartDetails.products.length > 0) {
      this.cartDetails.products.forEach((item) => {
        if (item.id === product.id) {
          this.selectedProduct.qty = item.qty;
        }
      });
    }

    if (this.selectedProduct.image) {
      this.productImage = this.selectedProduct.image;
    }
  }

  addToCart(product) {
    const userId = this.appService.getUserId();
    if (!userId) {
      this.appService.showError('Please login to add the items in the cart');
      return false;
    }
    if (this.cartDetails && this.cartDetails.length > 0 && this.storeData.id != this.cartDetails.store_id) {
      this.appService.showError('You have items in cart, please delete the items');
      return false;
    }
    if (isNaN(product.qty) || product.qty < 1 || product.qty > 10) {
      this.isError = true;
      return false;
    } else {
      this.isError = false;
    }
    if (this.cartDetails && this.cartDetails.products && this.cartDetails.products.length > 0) {
      const obj = this.cartDetails.products.find(o => o.id === product.id);
      if (!obj) {
        this.cartDetails.products.push(product);
      } else {
        this.cartDetails.products.forEach((item) => {
          if (item.id === product.id) {
            item.qty = product.qty;
          }
        });
      }
    } else {
      this.cartDetails.products = [product];
    }

    this.http.get(environment.baseURL + 'cart/read.php?userId=' + this.appService.getDInfoId()).subscribe((res: any) => {
      if (!res.records) {
        this.submitCartData();
      } else if (res.records && res.records.length > 0 && res.records[0].store_id === this.storeData.id) {
        this.updateCartData(res.records[0]);
      } else {
        this.appService.showError('You have items in cart, please delete the items');
      }
    });
  }

  submitCartData() {
    const cartProducts = JSON.stringify(this.cartDetails.products);
    const data = { store_id: this.storeData.id, user_id: this.appService.getDInfoId(), products: cartProducts };
    this.http.post(environment.baseURL + 'cart/create.php', data)
      .subscribe((res) => {
        this.appService.showSuccess('Product has been added into your cart successfully.');
        this.getCartItems();
        this.appService.getCartItems();
      });
  }

  updateCartData(cart) {

    const cartProducts = JSON.stringify(this.cartDetails.products);
    const data = { id: cart.id, store_id: cart.store_id, user_id: this.appService.getDInfoId(), products: cartProducts };
    this.http.post(environment.baseURL + 'cart/update.php', data)
      .subscribe((res) => {
        this.appService.showSuccess('Product has been updated into your cart successfully.');
        this.appService.setCartItems(this.cartDetails.products);
        this.appService.getCartItems();
        this.getCartItems();
        
      });
  }

  getAds(storeId) {
    this.http.get(environment.baseURL + 'ads/read.php?storeId=' + storeId).subscribe((res: any) => {
      if (res.records) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < res.records.length; i++) {
          if (res.records[i].position === 'bottom') {
            this.bottomAds.push(res.records[i]);
          }
          if (res.records[i].position === 'header') {
            this.headerAds.push(res.records[i]);
            this.appService.setAd(this.headerAds);
          }
          if (res.records[i].position === 'right') {
            this.rightAds.push(res.records[i]);
          }
        }
      }
    });
  }

  getPhotos(id) {
    this.http.get(environment.baseURL+'/photos/read.php?id=' + id)
      .subscribe((res: any) => {
        this.photos = res.records;
      });
  }

  showCart() {
    this.showDetails = true;

  }

  hideCart() {
    this.showDetails = false;
  }

  calculatePrice(item) {
    return Number(item.qty) * Number(item.amount);
  }

  multiDimensionalUnique(arr) {
    const uniques = [];
    const itemsFound = {};
    for (let i = 0, l = arr.length; i < l; i++) {
      const stringified = JSON.stringify(arr[i]);
      if (itemsFound[stringified]) { continue; }
      uniques.push(arr[i]);
      itemsFound[stringified] = true;
    }
    return uniques;
  }
  editProduct(product) {
    // this.route.navigate(['edit-product', this.storeData.id, this.storeData.catId, product.id]);
    // window.open( `/#/edit-product/${this.storeData.id}/${this.storeData.catId}/${product.id}/`, '_blank');
    window.location.href = `/#/edit-product/${this.storeData.id}/${this.storeData.catId}/${product.id}/`;
  }

  async filterBy(event){
    const value = event.target.value;
    await this.http.get(environment.baseURL + 'product/filter.php?storeId=' + this.storeData.id+'&filterId='+value)
      .subscribe((res: any) => {
        const arrr = [];
        this.productData = res.records;
        this.fbService.init(this.InitParams);
      });
  }

  async searchProduct(value){
    await this.http.get(environment.baseURL + 'product/search.php?storeId=' + this.storeData.id+'&searchName='+value)
      .subscribe((res: any) => {
        const arrr = [];
        this.productData = (res.records)?res.records:[];
        this.fbService.init(this.InitParams);
      });
  }

  async getChildCategory() {
      await  this.http.get(environment.baseURL+'child_category/read.php?cat_id=' + this.catId)
        .subscribe((res: any) => {
            if (res) {
                this.childCategories = res.records;
            }
        });
  }
  getSubCategory() {
    this.http.get(environment.baseURL +'sub_category/read.php?category_id=' + this.catId)
        .subscribe((res: any) => {
            if (res) {
                this.subCategories = res.records;
            }
        });
}
getChildCategoryByCategoryId(event){
  if(event.target.value > 0){
      this.http.get(environment.baseURL +'child_category/read.php?sub_cat_id=' + event.target.value)
      .subscribe((res: any) => {
          if (res) {
              this.childCategories = res.records;
          }
      });
  }
}
  async editProducts(product) {
    this.getChildCategory();
    this.getSubCategory();
    this.getProduct(product.id);
    
  }

getProduct(productId) {
  this.router.paramMap.subscribe(paramMap => {
      if (productId) {     
          this.http.get(environment.baseURL + 'product/read.php?productId=' + productId)
              .subscribe((res: any) => {
                  this.setProductData(res.records[0]);
              });
      }
  });
}
setProductData(data) {
  
  if (data.promotional == 1) {
      data.promotional = true;
  } else if (data.promotional == 0) {
      data.promotional = false;
  }
  this.productForm.controls.id.setValue(data.id);
  this.productForm.controls.store_id.setValue(data.storeId);
  this.productForm.controls.name.setValue(data.name);
  this.productForm.controls.child_cat_id.setValue(data.childCatId);
  this.productForm.controls.sub_cat_id.setValue(data.sub_cat_id);
  this.productForm.controls.quantity.setValue(data.quantity);
  this.productForm.controls.amount.setValue(data.amount);
  this.productForm.controls.is_available.setValue(data.isAvailable);
  this.productForm.controls.description.setValue(data.description);
  this.productForm.controls.details.setValue(data.details);
  this.productForm.controls.video_type.setValue(data.video_type);
  this.productForm.controls.video_url.setValue(data.video_url);
  this.productForm.controls.promotional.setValue(data.promotional);
  if (data.promotional) {
      this.productForm.controls['amount'].disable();
  }
}

get f() { return this.productForm.controls; }
onSubmit() {
  let url = '';
  let message = 'Product has been added successfully';
  url = environment.baseURL+'product/update.php';
  this.productForm.controls.store_id.setValue(this.storeData.id);
  this.submitted = true;
  if (this.productForm.invalid) {
      return;
  }
  console.log(this.productForm.getRawValue());
  const productData = this.productForm.getRawValue();
  productData.promotional = productData.promotional ? 1 : 0;
  this.http
      .post(url, productData)
      .subscribe((res: any) => {
          this.appService.showSuccess(message);
          return true;
      }, (error) => {
          this.appService.showError('Something went wrong.');
      });
}
videoTypeChange(event) {
  if (event) {
      this.productForm.controls['amount'].disable();
      this.productForm.controls['quantity'].disable();
  } else {
      this.productForm.controls['amount'].enable();
      this.productForm.controls['quantity'].enable();
  }
}
promotionalChange(event) {
  if (event.target.checked) {
      this.productForm.controls['amount'].disable();
  } else {
      this.productForm.controls['amount'].enable();
  }
}

async onCheckboxChange(event,productId){
  const changeProductAvailableStatusUrl = environment.baseURL+'product/changeProductAvailableStatus.php';
  let updateMessage = "Product Status Changed";
  await this.http
      .post(changeProductAvailableStatusUrl,{"id":productId,"is_available":event.target.value})
      .subscribe((res: any) => {
          this.appService.showSuccess(updateMessage);
          return true;    
      }, (error) => {
          this.appService.showError('Something went wrong.');
      });
}
editorConfig: AngularEditorConfig = {
  editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadUrl: 'v1/image',
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['bold', 'italic'],
    ['fontSize']
  ]
};

}
