import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-view-sub-category',
    templateUrl: './view-sub-category.component.html'
})
export class ViewSubCategoryComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name', 'catId', 'action'];
    dataSource = new MatTableDataSource([]);
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    categories = [];

    constructor(private appService: AppService, private http: HttpClient, private router: Router, public dialog: MatDialog) { }
    ngOnInit() {
        this.getCategory();
        this.getSubCategory();
    }

    getCategory() {
        this.http.get(environment.baseURL+'category/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.categories = res.records;
                }
            });
    }

    getSubCategory() {
        this.http.get(environment.baseURL+'sub_category/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.dataSource = new MatTableDataSource(res.records);
                    this.dataSource.sort = this.sort;
                }
            });
    }

    getCategoryName(id) {
        const category = this.categories.find((elem) => elem.id === id);
        if (category && category.name) {
            return category.name;
        } else {
            return '';
        }
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    editSubCategory(id) {
        this.router.navigate(['edit-sub-category', id]);
    }
    deleteSubCategory(id) {
        this.http.get(environment.baseURL+'sub_category/delete.php?id=' + id)
        .subscribe((res: any) => {
            if (res) {
                this.getSubCategory();
                this.appService.showSuccess('Sub category has been deleted successfully.');
            }
        }, (error) => {
            this.appService.showError('Something went wrong.');
        });
    }
}
