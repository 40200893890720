import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.css']
})
export class PasswordUpdateComponent implements OnInit {
  
  updatePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  reset_password_token: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.reset_password_token = paramMap.get('code');
      console.log("URL :" +environment.baseURL + 'registration/check_change_password_token.php?change_password_token=' + this.reset_password_token);
      if (this.reset_password_token) {
          this.http.get(environment.baseURL + 'registration/check_change_password_token.php?change_password_token=' + this.reset_password_token)
              .subscribe((res: any) => {
                if(res.status !== true){
                  this.router.navigate(['/']);
                }
          });
      }
  });

    this.updatePasswordForm = this.formBuilder.group({
       new_password: ['', Validators.required],
       confirm_password: ['', Validators.required]
       });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

   // convenience getter for easy access to form fields
   get f() { return this.updatePasswordForm.controls; }

   onSubmit() {
       this.submitted = true;
       // stop here if form is invalid
       if (this.updatePasswordForm.invalid) {
           return;
       }
      
      let formObject = this.updatePasswordForm.value;
      if(formObject.new_password.length < 6){
        this.appService.showError('Password must be at least 6 character');
        return false;
      }
      if(formObject.new_password != formObject.confirm_password){
        this.appService.showError('Password and confirm Password must be same');
        return false;
      }

       let headers = new HttpHeaders({
           'Access-Control-Allow-Credentials': 'true' 
        });
       let options = { headers: headers,  withCredentials: true};
       this.loading = true;
       let formData = this.updatePasswordForm.value;
       formData.reset_password_token = this.reset_password_token;
       this.http.post(environment.baseURL + 'change-password.php',formData ,options)
       .subscribe((res: any) => {
     if(res.status === true){
            this.appService.showSuccess(res.message);
            this.router.navigate(['/login']);
           }else{
            this.appService.showError(res.message);  
           }
       }, (error) => {
           this.loading = false;
           this.appService.showError('Please enter valid  password. ' + error.error.message);
       });
   }

}
