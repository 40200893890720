import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../shared/services/app.service';

@Component({
    selector: 'app-my-coupon-page',
    templateUrl: './my-coupon.component.html'
})
export class MyCouponComponent implements OnInit {
    couponForm: FormGroup;
    submitted = false;
    constructor(private formBuilder: FormBuilder, private http: HttpClient, private appService: AppService) { }
    ngOnInit() {
        this.couponForm = this.formBuilder.group({
            code: ['', Validators.required],
            email: ['', Validators.required],
        });
    }

    get f() { return this.couponForm.controls; }
    onSubmit() {
        const email = this.appService.getEmail();
        if (!email) {
            this.appService.showError('Please login or register to enroll the coupon');
            return false;
        } else {
            this.couponForm.controls.email.setValue(this.appService.getEmail());
        }
        this.submitted = true;
        if (this.couponForm.invalid) {
            return;
        }
        this.http
            .post('dinfokit/api/coupon/validate-coupon.php', this.couponForm.value)
            .subscribe((res: any) => {
                this.appService.showSuccess(res.message);
                return true;
            }, error => {
                this.appService.showError(error.error.message);
            });
    }
}
