import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../shared/services/app.service';

@Component({
    selector: 'app-my-order-page',
    templateUrl: './my-order.component.html',
    styleUrls: ['./my-order.component.css'],
})
export class MyOrderComponent implements OnInit {

    userId = '';
    orders = [];
    totalPrice;
    orderItems;
    orderConfirmUrl;
    orderRejectUrl;
    show: boolean = false;

    constructor(private http: HttpClient, private appService: AppService) {
        this.userId = this.appService.getDInfoId();
    }

    ngOnInit() {
        this.http.get(environment.baseURL+'order/read.php?userId=' + this.userId).subscribe((res: any) => {
            if (res.records && res.records.length > 0) {
                this.orders = res.records;
                this.orders.map(i => i.show = false)
                this.orderItems = JSON.parse(JSON.parse(this.orders[0].products));
                this.getTotalPrice();
            }
        });
    }

    showDetails(order) {
        order.show = !order.show;
    }

    confirmOrder(orderId){
        window.open(environment.baseURL + "order/customer.php?token="+encodeURIComponent(this.appService.getEmail()+","+orderId+",confirmed"));
    }

    cancelOrder(orderId){
        window.open(environment.baseURL + "order/customer.php?token="+encodeURIComponent(this.appService.getEmail()+","+orderId+",cancelled"));
    }
    identify(index, item) {
        return item.name;
    }

    calculatePrice(item) {
        return Number(item.qty) * Number(item.amount);
    }

    calculateTotalPrice(product){
        // alert(product);
        let totalPrice = 0;
        for (let i = 0; i < product.length; i++) {
            totalPrice += Number(product[i].qty) * Number(product[i].amount);
        }
        return totalPrice;
    }

    getTotalPrice() {
        this.totalPrice = 0;
        for (let i = 0; i < this.orderItems.length; i++) {
            this.totalPrice += Number(this.orderItems[i].qty) * Number(this.orderItems[i].amount);
        }
        return this.totalPrice.toFixed(2);
    }
}
