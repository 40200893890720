import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from './../../environments/environment';
import { AppService } from '../shared/services/app.service';


@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  
  userType;
  userId;
  notificationItems : any[];
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService) {
      this.userType = this.appService.getUserType();
      this.userId = this.appService.getDInfoId();
     }

  ngOnInit() {
    this.notificationList();
  }

  // Get Notification List

  notificationList(){
    this.http.get(environment.baseURL+'notification/read.php?user_id=' + this.userId+'&user_type='+this.userType+'&type=notification_list').subscribe((res: any) => {
      this.notificationItems = res.records;
      console.log("Notification Items : "+this.notificationItems)
    });
  }

  updateSeenStatus(id:number){
    const data = {
      "id" : id,
      "user_id" : this.userId,
      "user_type" : this.userType,
      "type" : "seen"
    }
    this.http.post(environment.baseURL+'notification/update.php',data).subscribe((res: any) => {
      this.notificationList();
    });
  }

}
