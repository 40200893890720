import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { AppService } from '../shared/services/app.service';
import { Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { ShopCardComponent } from './shop-card.component';
// import { Slick } from 'ngx-slickjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {


  constructor(private http: HttpClient, private appService: AppService) { }
  restaurantSlides: any = [[]];
  storeSlides: any = [[]];
  saloonSlides: any = [[]];
  restaurantList: any[];
  storeList: any = [];
  clothingList: any = [];
  jweleryList: any = [];
  categoryList: any = [];
  headerAds: any = [];
  topAds: any = [];
  middleAds: any = [];
  bottomAds: any = [];
  city = '';
  data = true;
  subscription: Subscription;
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 3000 };
  categories = [{ path: '/assets/img/cate-01.png', name: 'Grocery', route: '/list/Irving/1' },
  { path: '/assets/img/cate-02.png', name: 'Restaurant', route: '/list/Irving/2' },
  { path: '/assets/img/cate-03.png', name: 'jewellery', route: '/list/Irving/5' },
  { path: '/assets/img/cate-04.png', name: 'Clothing', route: '/list/Irving/6' },
  { path: '/assets/img/cate-05.png', name: 'IFK', route: '/list/Irving/7' },
  { path: '/assets/img/cate-05.png', name: 'Event & Hair Saloon', route: '/list/Irving/7' }];

  slideConfig1 = {
    infinite: true,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 3000
  };


  ngOnInit() {
    this.city = this.appService.city;
    this.subscription = this.appService.getCity().subscribe(city => {
      this.city = city.name;
      this.getAds();
      this.getStores();
      this.getRestaurant();
      this.getClothing();
      this.getJwelery();
    });
    this.getCategories();
    this.getAds();
    this.getStores();
    this.getRestaurant();
    this.getClothing();
    this.getJwelery();
  }

  ngAfterViewInit() {
    // document.getElementById('vid').play();
  }

  chunk(arr, chunkSize) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  getAds() {
    this.http.get(environment.baseURL + 'ads/read.php?city=' + this.city + '&page=home').subscribe((res: any) => {
      this.data = true;

      if (res.records) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < res.records.length; i++) {
          if (res.records[i].position === 'bottom') {
            this.bottomAds.push(res.records[i]);
          }
          if (res.records[i].position === 'middle') {
            this.middleAds.push(res.records[i]);
          }
          if (res.records[i].position === 'header') {
            this.headerAds.push(res.records[i]);
            this.appService.setAd(this.headerAds);
          }
          if (res.records[i].position === 'top') {
            this.topAds.push(res.records[i]);
          }
        }
      }
    }, err => {
      this.data = false;
    });
  }

  getRestaurant() {
    this.http.get(environment.baseURL + 'store/read.php?catId=2&record=6&city=' + this.city).subscribe((res: any) => {
      this.restaurantList = res.records;
    });
  }

  getStores() {
    this.http.get(environment.baseURL + 'store/read.php?catId=1&record=4&city=' + this.city).subscribe((res: any) => {
      this.storeList = res.records;
    });
  }

  getClothing() {
    this.http.get(environment.baseURL + 'store/read.php?catId=6&record=6&city=' + this.city).subscribe((res: any) => {
      this.clothingList = res.records;
    });
  }

  getJwelery() {
    this.http.get(environment.baseURL + 'store/read.php?catId=5&record=6&city=' + this.city).subscribe((res: any) => {
      this.jweleryList = res.records;
    });
  }

  getCategories() {
    this.http.get(environment.baseURL + 'category/read.php')
      .subscribe((res: any) => {
        if (res) {
          this.categoryList = res.records;
          this.categoryList = this.categoryList.map((item) => { item.checked = false; return item; });
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
