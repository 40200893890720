import { Component, OnInit, Inject ,ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-view-category',
    templateUrl: './view-category.component.html'
})
export class ViewCategoryComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name', 'action'];
    dataSource = new MatTableDataSource([]);
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    constructor(private appService: AppService, private http: HttpClient, private router: Router, public dialog: MatDialog) { }
    ngOnInit() {
        this.getCategory();
    }

    getCategory() {
        this.http.get(environment.baseURL+'category/read.php')
        .subscribe((res: any) => {
            if (res) {
                this.dataSource = new MatTableDataSource(res.records);
                this.dataSource.sort = this.sort;
            }
        });
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    editCategory(id) {
        this.router.navigate(['edit-category', id]);
    }

    deleteCategory(id) {
        this.http.get(environment.baseURL+'category/delete.php?id=' + id)
        .subscribe((res: any) => {
            if (res) {
                this.getCategory();
                this.appService.showSuccess('Category has been deleted successfully.');
            }
        }, (error) => {
            this.appService.showError('Something went wrong.');
        });
    }
}
