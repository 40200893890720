import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ListingPageComponent } from './listing-page/listing-page.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './registration/registration.component';
import { MyCouponComponent } from './my-coupon/my-coupon.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { ContactComponent } from './contact/contact.component';
import { SearchDataComponent } from './search-data/search-data.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { StoreImageGallaryComponent } from './store-image-gallary/store-image-gallary.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'list/:city', component: ListingPageComponent},
  {path: 'list/:city/:catId', component: ListingPageComponent},
  {path: 'details/:id', component: DetailPageComponent},
  {path: 'store-image-glarry/:id', component: StoreImageGallaryComponent},
  {path: 'search-data/:searchData',component:SearchDataComponent},
  {path: 'notification-list',component:NotificationListComponent},
  { path: 'login', component: LoginComponent },
  { path: 'change-password/:code',component:PasswordUpdateComponent},
  { path: 'register', component: RegisterComponent },
  { path: 'admin', children: [
    {
      path: '',
      loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule)
    }
  ]},
  { path: 'my-coupon', component: MyCouponComponent},
  { path: 'my-cart', component: MyCartComponent},
  { path: 'my-orders', component: MyOrderComponent},
  { path: 'my-profile', component: MyProfileComponent},
  { path: 'about-us', component: AboutComponent},
  { path: 'contact-us', component: ContactComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'order-details/:id',component:OrderDetailsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
