import { Component, OnInit, Inject , ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-product',
    templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {

    //temporarily removed these 'description', 'details',
    displayedColumns: string[] = ['id', 'name', 'childCatName','image', 'image_status', 'quantity',
        'amount', 'isAvailable', 'upload', 'photos', 'video_type', 'video_url', 'action'];
    productData: any;
    storeId = '';
    categoryId = '';
    fileToUpload: File = null;
   
    productId;
    photos = [];
    html;
    productMessage = 'Fetching products data .... ';
    constructor(private appService: AppService, private http: HttpClient,
                private router: ActivatedRoute, private route: Router, public dialog: MatDialog) { }

    ngOnInit() {
        this.router.paramMap.subscribe(paramMap => {
            this.storeId = paramMap.get('id');
            this.categoryId = paramMap.get('catId');
            console.log("storeIdt"+this.storeId)
            if (this.storeId) {
                this.getProducts(this.storeId);
            }
        });
    }

    getProducts(storeId) {
        this.http.get(environment.baseURL +'product/read.php?adminstoreId=' + storeId)
            .subscribe((res: any) => {
                this.productData = new MatTableDataSource(res.records);
            }, error => {
                this.productMessage = 'No products found';
            });
    }

    addProduct() {
        this.route.navigate(['add-product', this.storeId, this.categoryId]);
    }
    editProduct(id) {
        this.route.navigate(['edit-product', this.storeId, this.categoryId, id]);
    }

    applyFilter(event: Event) {
        // const filterValue = (event.target as htmlInputElement).value;
        // this.productData.filter = filterValue.trim().toLowerCase();
    }

    openDialog(element) {
        const dialogRef = this.dialog.open(DialogContentProductComponent, {
            data: { product: element }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    uploadProductExcelDialog( element, storeId, categoryId) {
        const dialogRef = this.dialog.open(DialogContentProductExcelComponent, {
            data: { storeId:storeId,categoryId:categoryId }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
    openZipDialog() {
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {

            data: {  }
        });

        dialogRef.afterClosed().subscribe(result => {
           
        });
    }

    handleFileInput(event, files: FileList) {
        this.fileToUpload = files.item(0);
        this.postFile(event, this.fileToUpload, this.productId);
    }
    postFile(event, fileToUpload: File, id) {
        const endpoint = environment.baseURL +'photos/create.php';
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload, fileToUpload.name);
        formData.append('product_id', id);
        return this.http
            .post(endpoint, formData)
            .subscribe((res) => {
                this.getPhotos();
                event.target.value = null;
                this.appService.showSuccess('Image has been uploaded successfully');
                return true;
            }, (error) => {
                event.target.value = null;
                this.appService.showError('Something went wrong while uploading the image');
            });
    }

    getPhotos() {
        this.http.get(environment.baseURL +'photos/read.php?id=' + this.productId)
            .subscribe((res: any) => {
                this.photos = res.records;
            });
    }

    deletePhoto(id) {
        this.http.get(environment.baseURL +'photos/delete.php?id=' + id)
            .subscribe((res: any) => {
                if (res) {
                    this.appService.showSuccess('Photo has been deleted successfully');
                    this.getPhotos();
                }
            }, (error) => {
                this.appService.showSuccess('Something went wrong');
            });
    }
    
}

@Component({
    selector: 'app-dialog-content-product',
    templateUrl: 'dialog-content-product.html',
})
export class DialogContentProductComponent {
    fileToUpload: File = null;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private appService: AppService) { }
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
    postFile(fileToUpload: File) {
        const endpoint = environment.baseURL +'product/upload.php';
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload, fileToUpload.name);
        formData.append('product_id', this.data.product.id);
        return this.http
            .post(endpoint, formData)
            .subscribe((res) => {
                this.appService.showSuccess('Image has been uploaded successfully');
                return true;
            }, (error) => {
                this.appService.showSuccess('Something went wrong while uploading the image');
            });
    }
}
@Component({
    selector: 'app-dialog-content-product-excel',
    templateUrl: 'dialog-content-product-excel.html',
    styleUrls: ['./dialog-content-product-excel.component.css'],
})
export class DialogContentProductExcelComponent {
    fileToUpload: File = null;
    productData: any;
    productMessage: any;
    storeId;
    categoryId;
    showModalBox:boolean = false;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient, private appService: AppService) { }
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
    postFile(fileToUpload: File) {
        console.log(this)
        const endpoint = environment.baseURL +'product/excel.php?store_id='+this.data.storeId+"&category_id="+this.data.categoryId;
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload, fileToUpload.name);
        formData.append('store_id', this.data.storeId);
        formData.append('cateogory_id', this.data.categoryId);
        return this.http
            .post(endpoint, formData)
            .subscribe((res: any) => {
                this.data.excel = res;
                if(res.status == 'success'){
                    this.appService.showSuccess('Product excel has been uploaded successfully');
                    return true;
                }else{
                    console.log(this.data.failed_rows)
                    let html = '<table>';
                    if(res.failed_rows.length > 0){
                        html += '<tr><td colspan="10">' + "Failed rows due to incomplete data." + '</td></tr>';
                        for(let i = 0; i < res.failed_rows.length; i++) {
                            for (let key in res.inserted_rows[0].data) {
                                html += '<tr><td>' + key+":"+res.inserted_rows[0].data[key] + '</td></tr>';
                            }
                        }
                        html += '</tr>';
                    }
                    html += '<tr><td colspan="10">' + "Inserted rows." + '</td></tr>';
                    if(res.inserted_rows.length > 0){
                        for(let i = 0; i < res.inserted_rows.length; i++) {
                            for (let key in res.inserted_rows[0].data) {
                                html += '<tr><td>' + key+":"+res.inserted_rows[0].data[key] + '</td></tr>';
                            }                      
                        }
                    }
                    html += '</table>';
                    this.http.get(environment.baseURL +'product/read.php?adminstoreId=' + this.data.storeId)
                    .subscribe((res: any) => {
                        this.productData = new MatTableDataSource(res.records);
                    }, error => {
                        this.productMessage = 'No products found';
                    });
                    return false;
                }
            }, (error) => {
                console.log(error)
                this.appService.showSuccess('Something went wrong while uploading the product excel');
            });
    }
}

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: './zipfile.component.html',
    
})
export class DialogOverviewExampleDialog {
    zipFileToUpload: File = null;
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient, private appService: AppService) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    // Zip File To Upload
    handleZipFileInput(files: FileList) {
        this.zipFileToUpload = files.item(0);
    }
    postZipFile(zipFileToUpload: File) {
        const endpoint = environment.baseURL +'product/upload-zip-image.php';
        const formData: FormData = new FormData();
        formData.append('zip_file', zipFileToUpload, zipFileToUpload.name);
        return this.http
            .post(endpoint, formData)
            .subscribe((res :any) => {
                if(res.status == true){
                    this.appService.showSuccess('From Zip All Image Uploaded Successfully');
                    this.onNoClick();
                    return true;
                }
            }, (error) => {
                this.appService.showError('Something went wrong while uploading the Zip');
            });
    }

}