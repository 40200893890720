import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-image-galary-add',
  templateUrl: './store-image-galary-add.component.html',
  styleUrls: ['./store-image-galary-add.component.css']
})
export class StoreImageGalaryAddComponent implements OnInit {
    storeImageGalaryAddForm: FormGroup;
    submitted = false;
    store = [];

  constructor(private appService: AppService, 
              private http: HttpClient,
              private router: ActivatedRoute,
              private route : Router,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getStore();
    this.buildFormGroup();
  }

  getStore() {
    this.http.get(environment.baseURL + 'store/read.php')
                .subscribe((res: any) => {
                   this.store = (res.records)?res.records:[];  
            });
  }

  get f() { return this.storeImageGalaryAddForm.controls; }

  buildFormGroup() {
    this.storeImageGalaryAddForm = this.formBuilder.group({
        storeId: ['',Validators.required],
        imageType: ['', Validators.required],
        image: ['',Validators.required]
    });
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.storeImageGalaryAddForm.get('image').setValue(file);
    }
  }
  onSubmit(){
    this.submitted = true;
    if(this.storeImageGalaryAddForm.invalid){
      return;
    }
    let formData: FormData = new FormData();
    let data = this.storeImageGalaryAddForm.value; 
    formData.append('store_id', data.storeId); 
    formData.append('avatar', this.storeImageGalaryAddForm.get('image').value); 
    formData.append('image_type', data.imageType);
    const endpoint = environment.baseURL+'store/create-image.php'; 
    return this.http
            .post(endpoint, formData)
            .subscribe((res:any) => {
                this.appService.showSuccess(res.message);
                this.route.navigate(['/store-image-galary']);;
            }, (error) => {
                this.appService.showError('Something went wrong while uploading the image');
            });
   
  }

}
