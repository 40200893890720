import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../shared/services/app.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shop-card',
  templateUrl: './shop-card.component.html',
})
export class ShopCardComponent {

  // @input decorator used to fetch the
  // property from the parent component.

  @Input() cardlist;
  @Input() title: string;
  @Input() type: string;
  city = '';
  subscription: Subscription;

  constructor(private appService: AppService, private router: ActivatedRoute) {
    this.city = this.appService.city;
    this.subscription = this.appService.getCity().subscribe(city => {
      this.city = city.name;
    });
  }
}
