import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule, MatIconModule, MatMenuModule,
  MatToolbarModule, MatSidenavModule, MatListModule,
  MatFormFieldModule, MatChipsModule, MatAutocompleteModule, MatSelectModule, MatDialogModule,
  MatTabsModule, MatSortModule, MatExpansionModule
} from '@angular/material';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ModalComponent } from './header/modal.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ShopCardComponent } from './home/shop-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ListingPageComponent } from './listing-page/listing-page.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { AppService } from './shared/services/app.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { LoginComponent, DialogForgotPasswordComponent } from './login/login.component';
import { RegisterComponent } from './registration/registration.component';
import { AdminModule } from './admin/admin.module';
import { MyCouponComponent } from './my-coupon/my-coupon.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { MyOrderComponent } from './my-order/my-order.component';
// import { JsonFormatterPipe } from './shared/pipe/json-formatter.pipe';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './auth/token-http-interceptor.service';
import { LoginService } from './login.service';
import { PipesModule } from './shared/pipe/pipes.module';
import { AboutComponent } from './about/about.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FacebookModule } from 'ngx-fb';
import { SearchDataComponent } from './search-data/search-data.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { DataTablesModule } from "angular-datatables";
import { OrderDetailsComponent } from './order-details/order-details.component';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { StoreImageGallaryComponent } from './store-image-gallary/store-image-gallary.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalComponent,
    FooterComponent,
    HomeComponent,
    ShopCardComponent,
    ListingPageComponent,
    DetailPageComponent,
    DialogComponent,
    LoginComponent,
    DialogForgotPasswordComponent,
    RegisterComponent,
    MyCouponComponent,
    MyCartComponent,
    MyOrderComponent,
    // JsonFormatterPipe,
    MyProfileComponent,
    AboutComponent,
    ContactComponent,
    TermsComponent,
    SearchDataComponent,
    NotificationListComponent,
    OrderDetailsComponent,
    PasswordUpdateComponent,
    StoreImageGallaryComponent,

  ],
  imports: [
    PipesModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatSortModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'
    }),
    DataTablesModule,
    AdminModule,
    SlickCarouselModule,
    NgxYoutubePlayerModule.forRoot(),
    FacebookModule.forRoot(),
    AngularEditorModule
  ],
  exports: [
    // JsonFormatterPipe,
  ],
  entryComponents: [DialogForgotPasswordComponent, ModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AppService, CookieService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
