import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatTableModule,
  MatInputModule,
  MatFormFieldModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatDialogModule,
  MatTabsModule,
  MatSortModule,
  MatExpansionModule,
} from "@angular/material";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  ProductComponent,
  DialogContentProductComponent,
  DialogContentProductExcelComponent,
  DialogOverviewExampleDialog,
} from "./product/product.component";
import {
  StoreComponent,
  DialogContentExampleDialogComponent,
  DealsDialogComponent,
} from "./store/store.component";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { AddEditStoreComponent } from "./add-edit-store/add-edit-store.component";
import { AddEditProductComponent } from "./add-edit-product/add-edit-product.component";
import { ViewCategoryComponent } from "./category/view-category/view-category.component";
import { ViewSubCategoryComponent } from "./sub-category/view-sub-category/view-sub-category.component";
import { ViewChildCategoryComponent } from "./child-category/view-child-category/view-child-category.component";
import { ViewAdsComponent } from "./ads/view-ads/view-ads.component";
import { ViewOrdersComponent } from "./view-orders/view-orders.component";
import { AddEditAdsComponent } from "./ads/add-edit-ads/add-edit-ads.component";
import { AddEditCategoryComponent } from "./category/add-edit-category/add-edit-category.component";
import { AddEditSubCategoryComponent } from "./sub-category/add-edit-sub-category/add-edit-sub-category.component";
import { AddEditChildCategoryComponent } from "./child-category/add-edit-child-category/add-edit-child-category.component";
import { PipesModule } from "./../shared/pipe/pipes.module";
import { StoreImageGalaryComponent } from "./store-image-galary/store-image-galary.component";
import { DataTablesModule } from "angular-datatables";
import { StoreImageGalaryAddComponent } from "./store-image-galary-add/store-image-galary-add.component";
import { StorageImageGalaryEditComponent } from "./storage-image-galary-edit/storage-image-galary-edit.component";

@NgModule({
  declarations: [
    AdminComponent,
    StoreComponent,
    AddEditStoreComponent,
    ProductComponent,
    AddEditProductComponent,
    DialogContentExampleDialogComponent,
    DialogContentProductComponent,
    ViewCategoryComponent,
    ViewSubCategoryComponent,
    ViewChildCategoryComponent,
    ViewAdsComponent,
    ViewOrdersComponent,
    AddEditAdsComponent,
    AddEditCategoryComponent,
    AddEditSubCategoryComponent,
    AddEditChildCategoryComponent,
    DealsDialogComponent,
    StoreImageGalaryComponent,
    StoreImageGalaryAddComponent,
    StorageImageGalaryEditComponent,
    DialogContentProductExcelComponent,
    DialogOverviewExampleDialog,
  ],
  imports: [
    PipesModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatInputModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatSortModule,
    MatExpansionModule,
    AdminRoutingModule,
    DataTablesModule,
    AngularEditorModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
    }),
  ],
  entryComponents: [
    DialogContentExampleDialogComponent,
    DialogOverviewExampleDialog,
    DialogContentProductComponent,
    DialogContentProductExcelComponent,
    DealsDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class AdminModule {}
