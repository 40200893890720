import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/services/app.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private appService: AppService,
        public dialog: MatDialog
    ) {
     }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
       


        let headers = new HttpHeaders({
            'Access-Control-Allow-Credentials': 'true' 
		});
        let options = { headers: headers,  withCredentials: true};
        this.loading = true;
        this.http.post(environment.baseURL + 'login.php', this.loginForm.value,options)
        .subscribe((res: any) => {
			if(res.verify_email == 0){
                this.appService.showError('Your Email is not verified, please verify your Email.');
            }else{
                localStorage.setItem('auth_token',res.jwt);
                this.appService.showSuccess('You are logged in successfully');
                this.loading = false;
                document.location.href = '/';
            }
        }, (error) => {
            this.loading = false;
            this.appService.showError('Please enter valid username and password. ' + error.error.message);
        });
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogForgotPasswordComponent, {
            data: { product: null }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    forgotPassword() {
        alert('Forgot Paddword');
    }
}

@Component({
    selector: 'app-dialog-forgot-password',
    templateUrl: 'dialog-forgot-password.html',
})
export class DialogForgotPasswordComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private appService: AppService) { }
    email = '';
    message = '';
    errorMessage = '';
    getEmail(email) {
        this.email = email;
    }
    sendNewPassword() {
        this.message = '';
        this.errorMessage = '';
        const endpoint = environment.baseURL+ 'forgot_password.php';
        const formData: FormData = new FormData();
        formData.append('email', this.email);
        return this.http
            .post(endpoint, formData)
            .subscribe((res) => {
                this.message = 'New Password Sent successfully to your email id.';
                this.appService.showSuccess('New Password Sent successfully to your email id.');
                return true;
            }, (error) => {
                this.errorMessage = error.error.message;
                this.appService.showError(error.error.message);
            });
    }
}
