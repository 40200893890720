import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-add-edit-category',
    templateUrl: './add-edit-category.component.html'
})
export class AddEditCategoryComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name'];
    dataSource: any;
    title = 'Add category';
    categoryForm: FormGroup;
    submitted = false;
    catId;

    constructor(private appService: AppService, private http: HttpClient,
                private router: ActivatedRoute, public dialog: MatDialog, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.buildFormGroup();
        this.getCategory();

    }

    get f() { return this.categoryForm.controls; }

    getCategory() {
        this.router.paramMap.subscribe(paramMap => {
            this.catId = paramMap.get('id');
            if (this.catId) {
                this.title = 'Edit Category';
                this.http.get(environment.baseURL + 'category/read.php?id=' + this.catId)
                    .subscribe((res: any) => {
                        this.setCategoryData(res.records[0]);
                });
            }
        });
    }

    buildFormGroup() {
        this.categoryForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required]
        });
    }
    setCategoryData(data) {
        this.categoryForm.controls.id.setValue(data.id);
        this.categoryForm.controls.name.setValue(data.name);
    }
    onSubmit() {
        let url = '';
        let message = 'Category has been created successfully';
        if (this.catId) {
            url = environment.baseURL+'category/update.php';
        } else {
            url = environment.baseURL+'category/create.php';
            message = 'Category has been updated successfully';
        }
        this.submitted = true;
        if (this.categoryForm.invalid) {
            return;
        }

        this.http
            .post(url, this.categoryForm.value)
            .subscribe((res) => {
                this.appService.showSuccess(message);
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }
}
