import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/services/app.service';

@Component({
    selector: 'app-register',
    templateUrl: 'registration.component.html'})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private http: HttpClient,
        private appService: AppService
    ) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            userid: [''],
            mobile: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            user_type: ['', Validators.required],
            address: ['', Validators.required],
            landmark: ['', Validators.required],
            country: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            zipcode: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.registerForm.patchValue({
            userid: this.registerForm.get('email').value,
            user_type: 'customer'
        });
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;
        this.http.post(environment.baseURL + 'registration/create.php', this.registerForm.value)
        .subscribe((res: any) => {
            this.loading = false;
            this.appService.showSuccess('We have send you verification link, please verify your email.');
            this.router.navigate(['/login']);
        }, (error) => {
            this.loading = false;
            this.appService.showError('Registration failed. ' + error.error.message);
        });
    }
}
