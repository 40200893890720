import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor() { }

  getAuthToken(): Object {
    return {
        'auth_token':localStorage.getItem('auth_token')
    }
  }
}