import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-add-edit-ads',
    templateUrl: './add-edit-ads.component.html'
})
export class AddEditAdsComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name'];
    stores: any;
    adForm: FormGroup;
    fileToUpload: File = null;
    isStore = false;
    submitted = false;
    title = 'Create New Ad';
    id;
    storeId;

    constructor(private appService: AppService, private http: HttpClient, private router: ActivatedRoute,
        public dialog: MatDialog, private formBuilder: FormBuilder) { }
    ngOnInit() {
        this.buildFormGroup();
        this.router.paramMap.subscribe(paramMap => {
            this.id = paramMap.get('id');
            this.title = 'Edit Ad';
        });
        this.getAd();
        this.getAllStore();
    }

    get f() { return this.adForm.controls; }

    buildFormGroup() {
        this.adForm = this.formBuilder.group({
            id: [''],
            page: ['', Validators.required],
            store_id: [0],
            path: ['', Validators.required],
            type: ['', Validators.required],
            position: ['', Validators.required],
            city: ['', Validators.required]
        });
    }
    getAd() {
        this.http.get(environment.baseURL + 'ads/read.php?id=' + this.id)
            .subscribe((res: any) => {
                if (res) {
                    this.setAdData(res.records[0]);
                }
            });
    }
    setAdData(data) {
        this.adForm.controls.id.setValue(data.id);
        this.adForm.controls.page.setValue(data.page);
        this.adForm.controls.store_id.setValue(data.storeId);
        this.adForm.controls.type.setValue(data.type);
        this.adForm.controls.position.setValue(data.position);
        this.adForm.controls.city.setValue(data.city);
        this.handleChange();
    }
    getAllStore() {
        this.http.get(environment.baseURL+'store/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.stores = res.records;
                }
            });
    }
    handleChange() {
        if (this.adForm.controls.page.value === 'Details') {
            this.isStore = true;
        }
    }
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
    postFile(fileToUpload: File) {
        const formData: FormData = new FormData();
        let message = 'Ad has been created successfully';
        let endpoint = 'dinfokit/api/ads/create.php';
        if (this.id) {
            endpoint = 'dinfokit/api/ads/update.php';
            formData.append('id', this.id);
            message = 'Ad has been updated successfully';
        }
        if (this.fileToUpload) {
            formData.append('avatar', fileToUpload, fileToUpload.name);
        }
        formData.append('store_id', this.adForm.controls.store_id.value);
        formData.append('page', this.adForm.controls.page.value);
        formData.append('type', this.adForm.controls.type.value);
        formData.append('city', this.adForm.controls.city.value);
        formData.append('position', this.adForm.controls.position.value);
        return this.http
            .post(endpoint, formData)
            .subscribe((res) => {
                this.appService.showSuccess(message);
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }
    onSubmit() {
        this.submitted = true;
        this.postFile(this.fileToUpload);
    }
}
