import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/shared/services/app.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-admin-add-edit-product',
    templateUrl: './add-edit-product.component.html'
})
export class AddEditProductComponent implements OnInit {

    title = 'Add Product';
    productForm: FormGroup;
    submitted = false;
    storeId = '';
    productId;
    childCategories = [];
    categoryId = '';
    checked = false;
    subCategories = [];

    constructor(private http: HttpClient, private formBuilder: FormBuilder, private router: ActivatedRoute,
        private appService: AppService, private route:Router) {

    }
    ngOnInit() {
        this.buildFormGroup();
        this.getProduct();
        // this.getChildCategory();
        this.getSubCategory();
    }

    buildFormGroup() {
        this.productForm = this.formBuilder.group({
            id: [''],
            store_id: ['', Validators.required],
            name: ['', Validators.required],
            quantity: ['', Validators.required],
            amount: ['', Validators.required],
            child_cat_id: ['', Validators.required],
            sub_cat_id: ['', Validators.required],
            is_available: ['', Validators.required],
            description: ['', Validators.required],
            details: ['', Validators.required],
            video_type: [''],
            video_url: [''],
            promotional: [''],
        });
    }

    getProduct() {
        this.router.paramMap.subscribe(paramMap => {
            this.storeId = paramMap.get('id');
            this.productId = paramMap.get('productId');
            this.categoryId = paramMap.get('catId');
            if (this.productId) {
                this.title = 'Edit Product';
                this.http.get(environment.baseURL + 'product/read.php?productId=' + this.productId)
                    .subscribe((res: any) => {
                        this.setProductData(res.records[0]);
                    });
            }
        });
    }
    getChildCategory() {
        this.http.get(environment.baseURL +'child_category/read.php?cat_id=' + this.categoryId)
            .subscribe((res: any) => {
                if (res) {
                    this.childCategories = res.records;
                }
            });
    }
    getChildCategoryByCategoryId(event){
        if(event.target.value > 0){
            this.http.get(environment.baseURL +'child_category/read.php?sub_cat_id=' + event.target.value)
            .subscribe((res: any) => {
                if (res) {
                    this.childCategories = res.records;
                }
            });
        }
    }
    getSubCategory() {
        this.http.get(environment.baseURL +'sub_category/read.php?category_id=' + this.categoryId)
            .subscribe((res: any) => {
                if (res) {
                    this.subCategories = res.records;
                }
            });
    }

    promotionalChange(event) {
        if (event.target.checked) {
            this.productForm.controls['amount'].disable();
        } else {
            this.productForm.controls['amount'].enable();
        }
    }

    videoTypeChange(event) {
        if (event) {
            this.productForm.controls['amount'].disable();
            this.productForm.controls['quantity'].disable();
        } else {
            this.productForm.controls['amount'].enable();
            this.productForm.controls['quantity'].enable();
        }
    }

    setProductData(data) {
        if (data.promotional == 1) {
            data.promotional = true;
        } else if (data.promotional == 0) {
            data.promotional = false;
        }
        this.productForm.controls.id.setValue(data.id);
        this.productForm.controls.store_id.setValue(data.storeId);
        this.productForm.controls.name.setValue(data.name);
        this.productForm.controls.child_cat_id.setValue(data.childCatId);
        this.productForm.controls.quantity.setValue(data.quantity);
        this.productForm.controls.amount.setValue(data.amount);
        this.productForm.controls.is_available.setValue(data.isAvailable);
        this.productForm.controls.description.setValue(data.description);
        this.productForm.controls.details.setValue(data.details);
        this.productForm.controls.video_type.setValue(data.video_type);
        this.productForm.controls.video_url.setValue(data.video_url);
        this.productForm.controls.promotional.setValue(data.promotional);
        if (data.promotional) {
            this.productForm.controls['amount'].disable();
        }
    }

    get f() { return this.productForm.controls; }

    onSubmit() {
        let url = '';
        let message = 'Product has been added successfully';
        if (this.storeId && this.productId) {
            url = environment.baseURL +'product/update.php';
            message = 'Product has been updated successfully';
        } else {
            url = environment.baseURL +'product/create.php';
        }
        this.productForm.controls.store_id.setValue(this.storeId);
        this.submitted = true;
        if (this.productForm.invalid) {
            return;
        }
        console.log(this.productForm.getRawValue());
        const productData = this.productForm.getRawValue();
        productData.promotional = productData.promotional ? 1 : 0;
        this.http
            .post(url, productData)
            .subscribe((res: any) => {
                this.appService.showSuccess(message);
                this.route.navigate(['/view-product/'+this.storeId+'/'+this.categoryId]);
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong.');
            });
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: 'auto',
          minHeight: '0',
          maxHeight: 'auto',
          width: 'auto',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Enter text here...',
          defaultParagraphSeparator: '',
          defaultFontName: '',
          defaultFontSize: '',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
          ],
          customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
        ]
    };
}
