import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { StoreComponent } from './store/store.component';
import { ProductComponent } from './product/product.component';
import { NgModule } from '@angular/core';
import { AdminGuardService } from './admin-gaurd.service';
import { AddEditStoreComponent } from './add-edit-store/add-edit-store.component';
import { AddEditProductComponent } from './add-edit-product/add-edit-product.component';
import { ViewCategoryComponent } from './category/view-category/view-category.component';
import { ViewSubCategoryComponent } from './sub-category/view-sub-category/view-sub-category.component';
import { ViewChildCategoryComponent } from './child-category/view-child-category/view-child-category.component';
import { ViewAdsComponent } from './ads/view-ads/view-ads.component';
import { ViewOrdersComponent } from './view-orders/view-orders.component';
import { AddEditAdsComponent } from './ads/add-edit-ads/add-edit-ads.component';
import { AddEditCategoryComponent } from './category/add-edit-category/add-edit-category.component';
import { AddEditSubCategoryComponent } from './sub-category/add-edit-sub-category/add-edit-sub-category.component';
import { AddEditChildCategoryComponent } from './child-category/add-edit-child-category/add-edit-child-category.component';
import { StoreImageGalaryComponent } from './store-image-galary/store-image-galary.component';
import { StoreImageGalaryAddComponent } from './store-image-galary-add/store-image-galary-add.component';
import { StorageImageGalaryEditComponent } from './storage-image-galary-edit/storage-image-galary-edit.component';
import { MerchantGuardService } from './merchant-guard.service';


const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        children: [
            {
                path: 'view-store',
                component: StoreComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path: 'add-store',
                component: AddEditStoreComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'edit-store/:id',
                component: AddEditStoreComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path: 'view-product/:id/:catId',
                component: ProductComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path: 'add-product/:id/:catId',
                component: AddEditProductComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path: 'edit-product/:id/:catId/:productId',
                component: AddEditProductComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path: 'view-category',
                component: ViewCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'add-category',
                component: AddEditCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'edit-category/:id',
                component: AddEditCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'view-sub-category',
                component: ViewSubCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'view-child-category',
                component: ViewChildCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'add-sub-category',
                component: AddEditSubCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'edit-sub-category/:id',
                component: AddEditSubCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'add-child-category',
                component: AddEditChildCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'edit-child-category/:id',
                component: AddEditChildCategoryComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'view-ads',
                component: ViewAdsComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'view-orders',
                component: ViewOrdersComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path: 'add-ad',
                component: AddEditAdsComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'edit-ad/:id',
                component: AddEditAdsComponent,
                canActivate: [AdminGuardService],
            },
            {
                path: 'store-image-galary',
                component: StoreImageGalaryComponent,
                canActivate: [MerchantGuardService],
            },
            {
                path :'store-image-galary-add',
                component:StoreImageGalaryAddComponent,
                canActivate:[MerchantGuardService],
            },
            {
                path: 'storage-image-galaray-edit/:id',
                component: StorageImageGalaryEditComponent,
                canActivate: [MerchantGuardService],
            }
        ]
    },
   /* {
        path: 'admin1/view-store',
        component: StoreComponent
    },
    {
        path: 'view-product',
        component: ProductComponent
    }
     children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'view-store'
        },
        {
            path: 'view-store',
            component: StoreComponent
        },
        {
            path: 'view-product',
            component: ProductComponent
        }
    ] */
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AdminRoutingModule { }
