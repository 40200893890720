import { Component, OnInit, Inject, ViewChild , AfterViewInit,OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../shared/services/app.service';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export interface Store {
    id: string;
    name: string;
    address: string;
  }
@Component({
    selector: 'app-view-orders-page',
    templateUrl: './view-orders.component.html',
    styleUrls: ['./view-orders.component.css'],
})
export class ViewOrdersComponent implements OnInit {
    userId = '';
    orders = [];
    itemIds = [];
    isShow = false;
    statuses = ['pending','confirmed','ready_to_pickup','closed','cancelled']
    checkbox;
    storeId;
    status = 'all';//'pending';
    stores: Store[] = [];
    userType;
    isAdmin = false;

    pendingOrder = 0;
    confirmedOrder = 0;
    readyToPickupOrder = 0;
    closedOrder = 0;
    cancelledOrder = 0;
    show: boolean = false;

    /** list of this.stores */
//   protected stores;

  /** control for the selected this.stores */
  public storeNameCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public storesFilterCtrl: FormControl = new FormControl();

  /** list of this.stores filtered by search keyword */
  public filteredStores: Store[] = [];
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
//   protected _onDestroy = new Subject<void>();
    
    constructor(private http: HttpClient, private appService: AppService, private router: Router) {
        this.userId = this.appService.getDInfoId();
        this.userType = this.appService.getUserType();
        if (this.userType === 'merchant' || this.userType === 'admin') {
            this.isAdmin = true;
        }
    }

    ngOnInit() {
        this.getStores();
        this.storeNameCtrl.setValue(this.stores);
        // listen for search field value changes
        this.storesFilterCtrl.valueChanges
        // .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
            this.filterStores();
        });
    }

    // ngOnDestroy() {
    //     // this._onDestroy.next();
    //     // this._onDestroy.complete();
    //   }
    
    protected filterStores() {
    if (!this.stores) {
        return;
    }
    // get the search keyword
    let search = this.storesFilterCtrl.value;
    if (!search) {
        this.filteredStores = this.stores;
        return;
    } else {
        search = search.toLowerCase();
    }
    // filter the stores
    this.filteredStores = this.stores.filter((store) => {
        return (store.name.toLowerCase().includes(search.toLowerCase()) || store.address.toLowerCase().includes(search.toLowerCase()));
    });
    }

    getStores(){
        this.http.get(environment.baseURL+'order/read.php?userId=' + this.userId+'&type=get_store_list'+'&status='+this.status).subscribe((res: any) => {
            this.stores = res['stores'];
            this.filteredStores = this.stores;
            if(this.stores.length == 1){
                this.storeId = this.stores[0].id;
                this.orders = res['orders']['records'];
            }else{
                this.getList();
            }
        });
    }

    getOrdersByStatus(status:any){
        this.status = status;
        this.storeId='';
        this.getList();
    }

    getStore(storeId:any){
        this.storeId = storeId;
        this.getList();
    }

    getList() {
        this.orders = [];
        this.getTotalCountZero();
        this.http.get(environment.baseURL+'order/read.php?userId=' + this.userId+'&status='+this.status+'&storeId='+this.storeId).subscribe((res: any) => {
            if(res.records){

                this.orders = res.records;
                this.getTotalCount();
            }else{
                this.orders = []; 
            }
        });
    }

    calculatePrice(item) {
        return Number(item.qty) * Number(item.amount);
    }

    cancel(order){
        order.showSave = false;
        order.showOptions = true;
    }

    confirmItems(id, type) {
        if(type == 'update' && !this.itemIds.length){
            this.appService.showError('Select atleast one item');
            return false;
        }
        let url = '';
        let message = 'Confirmation mail send to customer!';
        url = environment.baseURL+'order/update.php';
        this.http
            .post(url, { id, status: 'edit', item_ids: this.itemIds })
            .subscribe((res) => {
                this.appService.showSuccess(message);
                this.getList();
                this.itemIds = [];
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }

    updatePickUpStatus(id, status) {
        let url = '';
        let message;
        if (status === 1) {
            message = 'Order is ready to pick up';
        } else {
            message = 'Order Closed';
        }
        url = environment.baseURL+'order/update.php';
        this.http
            .post(url, { id, status })
            .subscribe((res) => {
                this.appService.showSuccess(message);
                this.getList();
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }

    toggleDisplay(order) {
        order.hideEdit = true;
        order.showSave = true;
        order.showOptions = false;
    }

    markItemUnavailable(order,itemId) {
        if(!order.showSave){
            this.appService.showError('Click Edit to delete the item');
            return false;
        }
        if(confirm("Are you sure to delete the item")) {
            order.products = JSON.parse(JSON.parse(order.products));
            let itemIndex = order.products.findIndex(item=> item.id === itemId);
            order.products[itemIndex]['status'] = "deleted";
            order.products = JSON.stringify(order.products);
            order.products = JSON.stringify(order.products);
            this.itemIds.push(itemId);
            this.confirmItems(order.id, 'update') ;
        }
    }

    identify(index, item) {
        return item.name;
    }
    changeOrderStatus(orderId,status) {
        let url = '';
        let message = 'Order Updated Successfully!';
        url = environment.baseURL+'order/updateOrder.php';
        this.http
            .post(url, { orderId,status:status })
            .subscribe((res) => {
                this.appService.showSuccess(message);
                this.getList();
                this.itemIds = [];
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }

    getTotalCount() {
        for (let i = 0; i < this.orders.length; i++) {
            if(this.orders[i].status=='pending'){
                this.pendingOrder = this.pendingOrder + 1;
            }else if(this.orders[i].status=='confirmed'){
                this.confirmedOrder = this.confirmedOrder + 1;
            }else if(this.orders[i].status == 'ready_to_pickup'){
                this.readyToPickupOrder = this.readyToPickupOrder +1 ;
            }else if(this.orders[i].status == 'closed'){
                this.closedOrder = this.closedOrder + 1;
            }else if(this.orders[i].status == 'cancelled'){
                this.cancelledOrder = this.cancelledOrder + 1;
            }
        }
        
    }
    getTotalCountZero(){
        this.pendingOrder = 0;
        this.confirmedOrder = 0;
        this.readyToPickupOrder = 0;
        this.closedOrder = 0;
        this.cancelledOrder = 0;
    }
    showDetails(order) {
        order.show = !order.show;
    }
}
