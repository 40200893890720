import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: 'dialog.component.html',
})
export class DialogComponent {
    test = '';
    constructor(
        public dialogRef: MatDialogRef<DialogComponent>) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
