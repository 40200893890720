import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Subscription } from 'rxjs';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-listing-page',
  templateUrl: './listing-page.component.html',
  styleUrls: ['./listing-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListingPageComponent implements OnInit, OnDestroy {

  items = [];
  categoryList = [];
  storeList = [];
  catId: any = 2;
  headerAds: any = [];
  rightAds = [];
  bottomAds = [];
  city = '';
  selectedId;
  subscription: Subscription;
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 3000 };
  constructor(private router: ActivatedRoute, private http: HttpClient, private appService: AppService) { }

  ngOnInit() {
    this.city = this.appService.city;
    this.subscription = this.appService.getCity().subscribe(city => {
      this.city = city.name;
      this.getStores(this.city);
      this.getAds();
    });
    if (this.city) {
      this.getAds();
    }
    this.router.paramMap.subscribe(paramMap => {
      const cityName = paramMap.get('city');
      if (paramMap.get('catId')) {
        this.catId = paramMap.get('catId');
      }
      this.getStores(cityName);
    });
  }

  getStores(cityName) {
    this.http.get(environment.baseURL + 'store/read.php?city=' + cityName)
      .subscribe((res: any) => {
        if (res) {
          this.items = res.records;
          this.storeList = res.records;
          this.getCategories();
        }
      });
  }

  getCategories() {
    this.http.get(environment.baseURL + 'category/read.php')
      .subscribe((res: any) => {
        if (res) {
          this.categoryList = res.records;
          this.categoryList = this.categoryList.map((item) => { item.checked = false; return item; });
          if (this.catId) {
            this.categoryList.map((cat) => {
              // tslint:disable-next-line:triple-equals
              if (this.catId == cat.id.toString()) {
                this.filterStore(cat);
              }
            });
          }
        }
      });
  }

  filterStore(category) {
    console.log(category)
    // this.selectedId = 2;
    this.items = this.storeList;
    this.selectedId = category.id;

    this.items = this.items.filter((item) => {
      // tslint:disable-next-line:triple-equals
      return category.id == item.catId;
    });

    if (this.selectedId === 0) {
      this.items = this.storeList;
    }
  }

  getAds() {
    this.http.get(environment.baseURL + 'ads/read.php?city=' + this.city + '&page=Listing').subscribe((res: any) => {
      if (res.records) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < res.records.length; i++) {
          if (res.records[i].position === 'bottom') {
            this.bottomAds.push(res.records[i]);
          }
          if (res.records[i].position === 'header') {
            this.headerAds.push(res.records[i]);
            this.appService.setAd(this.headerAds);
          }
          if (res.records[i].position === 'right') {
            this.rightAds.push(res.records[i]);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async searchProduct(value){
    await this.http.get(environment.baseURL + 'store/read.php?cityName=' +  this.city+'&cat_id='+this.selectedId+'&searchData='+value)
      .subscribe((res: any) => {
          this.items = (res.records)?res.records:[];
          this.storeList = (res.records)?res.records:[];
          // this.getCategories();
      });
  }
}
