import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storage-image-galary-edit',
  templateUrl: './storage-image-galary-edit.component.html',
  styleUrls: ['./storage-image-galary-edit.component.css']
})
export class StorageImageGalaryEditComponent implements OnInit {

  storeImageGalaryAddForm: FormGroup;
  submitted = false;
  store = [];
  imageId:any;

  constructor(private appService: AppService, 
              private http: HttpClient,
              private router: ActivatedRoute,
              private route : Router,
              private formBuilder: FormBuilder) { }

              ngOnInit() {
                this.getStore();
                this.buildFormGroup();
              }
            
              getStore() {
                this.router.paramMap.subscribe(paramMap => {
                  this.imageId = paramMap.get('id');
                  if (this.imageId) {
                      this.http.get(environment.baseURL + 'store/read-image.php?image_id=' + this.imageId)
                          .subscribe((res: any) => {
                            this.store = (res.records)?res.records:[];
                            if(this.store?.length > 0){
                              this.setGalaryImageData(res.records[0]);
                            }
                      });
                  }
              });
                // this.http.get(environment.baseURL + 'store/read.php')
                //             .subscribe((res: any) => {
                //                this.store = (res.records)?res.records:[];  
                //         });
              }
            
              get f() { return this.storeImageGalaryAddForm.controls; }
            
              buildFormGroup() {
                this.storeImageGalaryAddForm = this.formBuilder.group({
                    storeId: ['',Validators.required],
                    imageType: ['', Validators.required],
                    image: ['',Validators.required]
                });
              }
              onFileSelect(event) {
                if (event.target.files.length > 0) {
                  const file = event.target.files[0];
                  this.storeImageGalaryAddForm.get('image').setValue(file);
                }
              }
              onSubmit(){
                this.submitted = true;
                if(this.storeImageGalaryAddForm.invalid){
                  return;
                }
                let formData: FormData = new FormData();
                let data = this.storeImageGalaryAddForm.value; 
                formData.append('id', this.imageId); 
                formData.append('avatar', this.storeImageGalaryAddForm.get('image').value); 
                const endpoint = environment.baseURL+'store/update-image.php'; 
                return this.http
                        .post(endpoint, formData)
                        .subscribe(() => {
                            this.appService.showSuccess('Image has been updated successfully');
                            this.route.navigate(['/store-image-galary']);;
                        }, (error) => {
                            this.appService.showError('Something went wrong while uploading the image');
                        });
               
              }

              setGalaryImageData(data){
                this.storeImageGalaryAddForm.controls.storeId.setValue(data.store_name);
                this.storeImageGalaryAddForm.controls.imageType.setValue(data.image_type);
              }

}
