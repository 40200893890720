import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(private http: HttpClient, private toastr: ToastrService, private cs: CookieService) { }
    private subject = new Subject<any>();
    private headerSubject = new Subject<any>();
    private cartItemsSubject = new Subject<any>();
    numberOfCartItem = new Subject<any>();
    city = '';
    headerAd = [];

    getCategoryStoresName(text) {
        const headers: HttpHeaders = new HttpHeaders();
        headers.set('content-type', 'application/json');
        this.http.get(environment.baseURL + 'category/read.php?searchedText=' + text, { headers }).subscribe((res) => {
            return res;
        });
    }

    showSuccess(message) {
        this.toastr.success(message, '');
    }

    showError(message) {
        this.toastr.error(message);
    }
    getEmail() {
        return this.cs.get('dInfoUserEmail');
    }
    getUserType() {
        return this.cs.get('dInfoUserType');
    }
    getUserId() {
        return this.cs.get('dInfoUserId');
    }
    getUserName() {
        return this.cs.get('userName');
    }

    getDInfoId() {
        return this.cs.get('dInfoId');
    }
    deleteAllCookie() {
		this.cs.deleteAll('/', 'localhost');
        this.cs.deleteAll('/', environment.cookieDomain);
    }
    setCity(city) {
        this.city = city;
        this.subject.next({ name: city });
    }
    getCity() {
        return this.subject.asObservable();
    }
    setAd(ad) {
        this.headerAd = ad;
        this.headerSubject.next(ad);
    }
    getAd() {
        return this.headerSubject.asObservable();
    }

    setCartItems(cartItems) {
        this.cartItemsSubject.next(cartItems);
        this.getCartItems();

    }
    getCartItems() {
        return this.cartItemsSubject.asObservable();
    }

    
}
