import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Subscription } from 'rxjs';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-search-data',
  templateUrl: './search-data.component.html',
  styleUrls: ['./search-data.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchDataComponent implements OnInit {
  subscription: any;
  items = [];
  storeList = [];

  constructor(private route:Router,private router: ActivatedRoute, private http: HttpClient, private appService: AppService) { }

  ngOnInit() {
    this.router.paramMap.subscribe(paramMap => {
      const searchData = paramMap.get('searchData');
      this.http.get(environment.baseURL + 'store/read.php?cityName=Irving&searchData='+searchData)
      .subscribe((res: any) => {
          this.items = (res.records)?res.records:[];
          this.storeList = (res.records)?res.records:[];
          // console.log(this.items)
      });
      
    });
  }
  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

}
