import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  
  OrderDetails=[];
  orderId:any;
  totalPrice;
  orderItems;

  constructor(private appService: AppService, 
    private http: HttpClient,
    private router: ActivatedRoute,
    private route : Router) { }

  ngOnInit() {
      this.router.paramMap.subscribe(paramMap => {
        this.orderId = paramMap.get('id');
        if (this.orderId) {
            this.http.get(environment.baseURL + 'order/readByOrderId.php?OrderId=' + this.orderId)
                .subscribe((res: any) => {
                  this.OrderDetails = (res.records)?res.records:[];
                  this.orderItems = JSON.parse(JSON.parse(this.OrderDetails[0].products));
                  this.getTotalPrice();
            });
        }
    });
  }

  getTotalPrice() {
    this.totalPrice = 0;
    for (let i = 0; i < this.orderItems.length; i++) {
        this.totalPrice += Number(this.orderItems[i].qty) * Number(this.orderItems[i].amount);
    }
    return this.totalPrice.toFixed(2);

}

calculatePrice(item) {
  return Number(item.qty) * Number(item.amount);
}

}
