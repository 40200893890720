import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../shared/services/app.service';

@Component({
    selector: 'app-my-profile-page',
    styleUrls: ['./my-profile.component.css'],
    templateUrl: './my-profile.component.html'
})
export class MyProfileComponent implements OnInit {
    userProfileForm: FormGroup;
    userId = '';
    profileDetails;
    loading = false;
    submitted = false;
    edit = false;
    addressForm: FormGroup;
    selectedAddress;
    customerAddress = '';
    deliveryAddress = null;
    email;
    name;
    profileTitle;

    constructor(private http: HttpClient, private appService: AppService,
                private formBuilder: FormBuilder,
    ) {
        this.userId = this.appService.getEmail();
        this.email = this.appService.getEmail();
        this.name = this.appService.getUserName();
    }

    ngOnInit() {
        this.userProfileForm = this.formBuilder.group({
            id: '',
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            userid: [''],
            mobile: ['', Validators.required],
            email: ['', Validators.required],
            user_type: ['', Validators.required]
        });
        this.getProfileDetails();
        this.getDeliveryAddress();
        this.addressForm = this.formBuilder.group({
            id: '',
            email: [''],
            address: ['', Validators.required],
            landmark: ['', Validators.required],
            country: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            zipcode: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobile: ['', Validators.required]
        });
        

    }

    getProfileDetails() {
        this.http.get(environment.baseURL+'profile/read.php?id=' + this.userId).subscribe((res: any) => {
            this.profileDetails = res.records[0];
            Object.keys(this.profileDetails).forEach(k => {
                const control = this.userProfileForm.get(k);
                if (control) {
                    control.setValue(this.profileDetails[k], { onlySelf: true });
                    this.getProfileTitle();
                }
            });
            // convenience getter for easy access to form fields
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.userProfileForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.userProfileForm.patchValue({
            id: this.profileDetails.id,
            userid: this.userProfileForm.get('email').value,
        });
        // stop here if form is invalid
        if (this.userProfileForm.errors) {
            return;
        }
        this.loading = true;
        this.http.post(environment.baseURL + 'registration/update.php', this.userProfileForm.value)
            .subscribe((res: any) => {
                this.loading = false;
                this.edit = false;
                this.appService.showSuccess('Profile updated successfully.');
                this.getProfileDetails();
                return true;
            }, (error) => {
                this.loading = false;
                this.appService.showError('Profile Update failed. ' + error.error.message);
            });
    }

    identify(index, item) {
        return item.name;
    }

    getDeliveryAddress() {
        this.http.get(environment.baseURL+'customer_address/read.php?email=' + this.appService.getEmail()).subscribe((res: any) => {
            if (res.records) {
                this.deliveryAddress = res.records;
            }
        });
    }

    updateAddress() {
        this.addressForm.patchValue({
            id:this.selectedAddress.id
        });
        // stop here if form is invalid
        if (this.addressForm.invalid) {
            return;
        }
        this.http.post(environment.baseURL + 'customer_address/update.php', this.addressForm.value)
        .subscribe((res: any) => {
            this.appService.showSuccess('Address updated successfully');
            this.getDeliveryAddress();
        }, (error) => {
            this.appService.showError('Address saving failed. ' + error.error.message);
        });
    }

    addEditAddress(address) {
        this.selectedAddress = address;
        if (address) {
            this.addressForm.controls.address.setValue(address.address);
            this.addressForm.controls.landmark.setValue(address.landmark);
            this.addressForm.controls.zipcode.setValue(address.zipcode);
            this.addressForm.controls.country.setValue(address.country);
            this.addressForm.controls.state.setValue(address.state);
            this.addressForm.controls.city.setValue(address.city);
            this.addressForm.controls.firstName.setValue(address.first_name);
            this.addressForm.controls.lastName.setValue(address.last_name);
            this.addressForm.controls.mobile.setValue(address.mobile);
        } else {
            this.addressForm.reset();
        }
    }

    deleteAddress(addressId) {
        if (confirm("Are you sure to delete the address?")) {
            this.http.post(environment.baseURL + 'customer_address/delete.php', { id: addressId })
                .subscribe((res: any) => {
                    this.appService.showSuccess('Address deleted successfully');
                    this.getDeliveryAddress();
                }, (error) => {
                    this.appService.showError('Address deletion failed. ' + error.error.message);
                });
        }
    }

    getProfileTitle(){
         this.profileTitle = this.profileDetails?.firstName. substr(0,1). toUpperCase();
         this.profileTitle += this.profileDetails?.lastName. substr(0,1). toUpperCase();
    }
}
