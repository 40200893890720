import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { AppService } from '../shared/services/app.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacebookService, InitParams } from 'ngx-fb';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-store-image-gallary',
  templateUrl: './store-image-gallary.component.html',
  styleUrls: ['./store-image-gallary.component.css']
})
export class StoreImageGallaryComponent implements OnInit {
  
  storeData: any;
  catId: 0;
  storeImageGalarayData = [];
  storeId;

  constructor(private fbService: FacebookService, private router: ActivatedRoute, private http: HttpClient,
    private appService: AppService, private route: Router) { 
    this.router.paramMap.subscribe(paramMap => {
        const storeId = paramMap.get('id');
        this.storeId = storeId;
        if (storeId) {
          this.getStore(storeId);
          this.getStoreImageGalary(storeId);
          
        }
      });
    }

  ngOnInit() {
  }

  getStore(storeId) {
    this.http.get(environment.baseURL + 'store/read.php?storeId=' + storeId)
      .subscribe((res: any) => {
        this.storeData = res.records[0];
        this.catId = this.storeData.catId;
      });
  }
  getStoreImageGalary(storeId){
    this.http.get(environment.baseURL+'store/read-image.php?store_id='+storeId)
    .subscribe((res: any) => {
      this.storeImageGalarayData = (res.records)?res.records:[];
    });
  }

  

}
