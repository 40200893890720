import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppService } from '../shared/services/app.service';


@Injectable({
    providedIn: 'root'
})

export class MerchantGuardService implements CanActivate {
    constructor(private appService: AppService, private router: Router) {

    }
    canActivate(): boolean {
        const userType = this.appService.getUserType();
        if (userType === 'merchant' || userType === 'admin' || userType === 'super admin') {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
}
