import { Component, OnInit, Inject , ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-store',
    templateUrl: './store.component.html'
})
export class StoreComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name', 'image', 'deals', 'cat_name',
        'priority', 'address', 'city', 'zipcode','whatsapp_url', 'products', 'upload', 'dealsUpload', 'action'];
    dataSource = new MatTableDataSource([]);
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    userType = null;

    constructor(private appService: AppService, private http: HttpClient, private router: Router, public dialog: MatDialog) { }

    ngOnInit() {
        this.userType = this.appService.getUserType();
        if (this.userType === 'merchant') {
            this.getStore(this.appService.getEmail());
        }
        if (this.userType === 'admin' || this.userType === 'super admin') {
            this.getAllStore();
        }
    }
    getStore(email) {
        this.http.get(environment.baseURL+'store/read.php?email=' + email)
            .subscribe((res: any) => {
                if (res) {
                    this.dataSource = new MatTableDataSource(res.records);
                }
            });
    }
    getAllStore() {
        this.http.get(environment.baseURL+'store/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.dataSource = new MatTableDataSource(res.records);
                    this.dataSource.sort = this.sort;
                }
            });
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    addStore() {
        this.router.navigate(['add-store']);
    }
    editStore(id) {
        this.router.navigate(['edit-store', id]);
    }

    openDialog(element) {
        const dialogRef = this.dialog.open(DialogContentExampleDialogComponent, {
            data: { store: element }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openDealsDialog(element) {
        const dialogRef = this.dialog.open(DealsDialogComponent, {
            data: { store: element }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}

@Component({
    selector: 'app-dialog-content-example-dialog',
    templateUrl: 'dialog-content-example-dialog.html',
})
export class DialogContentExampleDialogComponent {
    fileToUpload: File = null;
    storeComponent = new StoreComponent(this.appService, this.http, null, null);
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private appService: AppService) { }
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
    postFile(fileToUpload: File) {
        const endpoint = environment.baseURL+'store/upload.php';
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload, fileToUpload.name);
        formData.append('store_id', this.data.store.id);
        return this.http
            .post(endpoint, formData)
            .subscribe(() => {
                this.appService.showSuccess('Image has been uploaded successfully');
                const userType = this.appService.getUserType();
                if (userType === 'merchant') {
                    this.storeComponent.getStore(this.appService.getEmail());
                }
                if (userType === 'admin' || userType === 'super admin') {
                    this.storeComponent.getAllStore();
                }
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong while uploading the image');
            });
    }
}
@Component({
    selector: 'app-deals-dialog',
    templateUrl: 'deals-dialog.html',
})
export class DealsDialogComponent {
    fileToUpload: File = null;
    storeComponent = new StoreComponent(this.appService, this.http, null, null);
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private appService: AppService) { }
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
    postFile(fileToUpload: File) {
        const endpoint = environment.baseURL+'store/deals-upload.php';
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload, fileToUpload.name);
        formData.append('store_id', this.data.store.id);
        return this.http
            .post(endpoint, formData)
            .subscribe(() => {
                this.appService.showSuccess('Image has been uploaded successfully');
                const userType = this.appService.getUserType();
                if (userType === 'merchant') {
                    this.storeComponent.getStore(this.appService.getEmail());
                }
                if (userType === 'admin' || userType === 'super admin') {
                    this.storeComponent.getAllStore();
                }
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong while uploading the image');
            });
    }
}
