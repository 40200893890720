import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-admin-add-edit-store',
    templateUrl: './add-edit-store.component.html'
})
export class AddEditStoreComponent implements OnInit {

    title = 'Add Store';
    storeForm: FormGroup;
    submitted = false;
    storeId;
    categories = [];

    constructor(private http: HttpClient, private appService: AppService,
                private formBuilder: FormBuilder, private router: ActivatedRoute,
                private route:Router) {

    }
    ngOnInit() {
        this.buildFormGroup();
        this.getStore();
        this.getCategory();
    }

    buildFormGroup() {
        this.storeForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            cat_id: ['', Validators.required],
            priority: ['', Validators.required],
            address: ['', Validators.required],
            zipcode: ['', Validators.required],
            city: ['', Validators.required],
            whatsapp_url: [''],
            delivery: ['']
        });
    }

    getCategory() {
        this.http.get(environment.baseURL +'category/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.categories = res.records;
                }
            });
    }

    getStore() {
        this.router.paramMap.subscribe(paramMap => {
            this.storeId = paramMap.get('id');
            this.title = this.storeId ? 'Edit Store' : 'Add Store';
            this.http.get(environment.baseURL + 'store/read.php?storeId=' + this.storeId)
                .subscribe((res: any) => {
                    // this.storeData = res.records[0];
                    this.setStoreData(res.records[0]);
                });
        });
    }

    setStoreData(data) {
        this.storeForm.controls.id.setValue(data.id);
        this.storeForm.controls.name.setValue(data.name);
        this.storeForm.controls.cat_id.setValue(data.catId);
        this.storeForm.controls.priority.setValue(data.priority);
        this.storeForm.controls.address.setValue(data.address);
        this.storeForm.controls.zipcode.setValue(data.zipcode);
        this.storeForm.controls.city.setValue(data.city);
        this.storeForm.controls.whatsapp_url.setValue(data.whatsapp_url);
        this.storeForm.controls.delivery.setValue(data.delivery);
    }

    get f() { return this.storeForm.controls; }

    onSubmit() {
        let url = '';
        let message = 'Store has been created successfully';
        if (this.storeId) {
            url = environment.baseURL +'store/update.php';
            message = 'Store has been updated successfully';
        } else {
            url = environment.baseURL +'store/create.php';
        }
        this.submitted = true;
        if (this.storeForm.invalid) {
            return;
        }

        this.http
            .post(url, this.storeForm.value)
            .subscribe((res) => {
                this.appService.showSuccess(message);
                this.route.navigate(['/view-store']);
                return true;
            }, (error) => {
                this.appService.showError('Something went wrong');
            });
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: 'auto',
          minHeight: '0',
          maxHeight: 'auto',
          width: 'auto',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Enter text here...',
          defaultParagraphSeparator: '',
          defaultFontName: '',
          defaultFontSize: '',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
          ],
          customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
        ]
    };
}
