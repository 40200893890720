import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  userType = null;
  constructor(private appService: AppService) {
    this.userType = this.appService.getUserType();
  }
}
