import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/services/app.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-view-ads',
    templateUrl: './view-ads.component.html'
})
export class ViewAdsComponent implements OnInit {
    displayedColumns: string[] = ['id','store_name', 'page', 'path', 'type', 'city', 'position', 'action'];
    categories = [];
    dataSource = new MatTableDataSource([]);
    @ViewChild(MatSort, {static: false}) sort: MatSort;


    constructor(private appService: AppService, private http: HttpClient, private router: Router, public dialog: MatDialog) { }
    ngOnInit() {
        this.getAds();
    }

    getAds() {
        this.http.get(environment.baseURL+'ads/read.php')
            .subscribe((res: any) => {
                if (res) {
                    this.dataSource = new MatTableDataSource(res.records);
                    this.dataSource.sort = this.sort;
                }
            });
    }
    editAd(id) {
        this.router.navigate(['edit-ad', id]);
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    deleteAd(id) {
        this.http.get(environment.baseURL+'ads/delete.php?id=' + id)
            .subscribe((res: any) => {
                if (res) {
                    this.appService.showSuccess('Ad has been deleted successfully');
                    this.getAds();
                }
            }, (error) => {
                this.appService.showSuccess('Something went wrong');
            });
    }
}
