import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  contactForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      subject: ['',  Validators.compose([Validators.required, Validators.minLength(5)])],
      message: ['', [Validators.required,Validators.minLength(5)]]
      });
   this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    if(!this.appService.getEmail()){
      this.appService.showError("Please Login .");
          return;
    }
   
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.appService.showError("Enter Valid Details");
        return;
    }
    let headers = new HttpHeaders({
        'Access-Control-Allow-Credentials': 'true' 
     });
    let options = { headers: headers,  withCredentials: true};
    this.loading = true;
    let formData = this.contactForm.value;
    formData.email = this.appService.getEmail();

    this.http.post(environment.baseURL + 'contact-us.php',formData ,options)
    .subscribe((res: any) => {
  if(res.status === true){
         this.appService.showSuccess(res.message);
        }else{
         this.appService.showError(res.message);  
        }
    });
}

}
