import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from './../../environments/environment';
import { AppService } from '../shared/services/app.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from './modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
 
  numberOfCartItem = 0;
  numberOfNotificationCount = 0;
  notificationItems = [];
  cityControl = '';
  stateControl = new FormControl('', Validators.required);
  stateList: any[];
  cityList: any[] = [];
  zipcodeList: any[];
  selectedState: any;
  selectedCity: any;
  selectedZipcode: any;
  isHomePage = false;
  title = 'Login';
  animal: string;
  name: string;
  isAdmin = false;
  userType;
  ads = [];
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 3000 };
  showMenu = false;
  userId;
  cartItems = [];
  cartDetails: any = [];
  searchForm = this.formBuilder.group({
    searchData: '',
  });

  constructor(private http: HttpClient, private router: Router, private formBuilder: FormBuilder,
              private route: ActivatedRoute, private appService: AppService, public matDialog: MatDialog
              ) {

    this.userType = this.appService.getUserType();
    this.userId = this.appService.getDInfoId();
    if (this.userType === 'merchant' || this.userType === 'admin') {
      this.isAdmin = true;
    }
    this.name = this.appService.getUserName();
    router.events.subscribe( (event) => {
      if (event instanceof NavigationStart) {
          this.showMenu = false;
          window.scroll(0, 0);
      }
  });
  this.appService.numberOfCartItem.subscribe(numberOfCartItem => {
    this.numberOfCartItem = numberOfCartItem;
  });
  }

  ngOnInit() {
    this.ads = this.appService.headerAd;
    this.appService.getAd().subscribe((res: any) => {
      this.ads = res;
    });

    this.appService.getCartItems().subscribe((res: any) => {
      this.cartItems = res;
    });
    this.getSuggestion();
    this.router.events.subscribe(event => {
      const url = window.location.href;
      if (url.includes('home')) {
        this.isHomePage = true;
      } else {
        this.isHomePage = false;
      }
    });
    this.getCartItems();
    this.getNotificationCount();
    this.getNotification();
    if(this.name){
      setInterval(() => {
        this.getNotificationCount();
        this.getNotification(); 
      }, 5000);
    }
    // this.cityControl.valueChanges.subscribe((value)=> {
    //   this.selectedCity = value;
    // });
  }

  getCartItems() {
   
    this.http.get(environment.baseURL+'cart/read.php?userId=' + this.userId).subscribe((res: any) => {
      if (res && res.records && res.records[0] && res.records[0].products) {
        this.cartDetails = res.records[0];
        const cartdata = JSON.parse(res.records[0].products);
        this.appService.setCartItems(cartdata);
        this.numberOfCartItem = 0;
        for(let cartDt of cartdata){
          this.numberOfCartItem = this.numberOfCartItem + cartDt.qty;
        }
        this.appService.numberOfCartItem.next(this.numberOfCartItem);
      }
    });
  }

  getSuggestion() {
    this.http.get(environment.baseURL + 'state/read.php').subscribe((res: any) => {
      this.stateList = res.records;
      if (this.stateList.length === 1) {
        if (this.stateList && this.stateList.length > 0) {
          this.stateControl.setValue(this.stateList[0]);
          this.getCitySuggestion();
        }
      }
    });
  }

  getCitySuggestion() {
    this.http.get(environment.baseURL + 'city/read.php?stateId=' + this.stateControl.value.id)
      .subscribe((res: any) => {
        this.cityList = res.records;
        const selectedCity = res.records.find((item) => {
          return item.city === 'Irving';
        });
        this.appService.setCity(selectedCity.city);
        this.cityControl = selectedCity.city;
      });
  }

  setCity() {
    this.appService.setCity(this.cityControl);
  }

  search() {
    if (this.stateControl.value && this.cityControl) {
      this.router.navigate(['list', this.cityControl]);
    }
  }

  productQuantity(product) {
    if (isNaN(product.qty) || product.qty < 1 || product.qty > 10) {
        this.appService.showError('Product quantity should be between 1 to 10 and only digits are allowed.');
        return false;
    }
    this.cartItems.filter((item, index) => {
        if (item.id === product.id) {
            item.qty = product.qty;
            if (product && product.length) {
                this.http.post(environment.baseURL + '/dinfokit/api/cart/delete.php?userId=' + this.userId, product.id)
                    .subscribe((res) => {
                        this.appService.showSuccess('Product quantity has been updated successfully.');
                    });
            } else {
                this.updateCartData(this.cartItems, this.cartDetails, 'Product quantity has been updated successfully.');
              
            }
        }
    });
}

deleteItemFromCart(product) {
    this.cartItems.filter((item, index) => {
        if (item.id === product.id) {
            this.cartItems.splice(index, 1);
            this.updateCartData(this.cartItems, this.cartDetails, 'Product has been deleted from your cart successfully.');
        }
    });
}

updateCartData(product, cart, msg) {
    let items = this.cartItems;
    if (!this.cartItems.length) {
        items = [];
    }
    let url = '';
    if (this.cartItems.length) {
        url = environment.baseURL + 'cart/update.php';
    } else {
        url = environment.baseURL + 'cart/delete.php?userId=' + this.userId;
    }
    const data = {
      id: this.cartDetails.id,
      store_id: this.cartDetails.store_id,
      user_id: this.appService.getDInfoId(), products: JSON.stringify(items)
    };
    this.http.post(url, data)
        .subscribe((res) => {
            this.getCartItems();
            this.appService.showSuccess(msg);
        });
}

  logout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '180px';
    dialogConfig.width = '500px';
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }
  onSubmit() {
    const formData = this.searchForm.value;
    this.searchForm.reset();
    if(formData.searchData!=null){
        this.router.navigate(['search-data',formData.searchData]);
     }
  }

  getNotificationCount(){
    this.http.get(environment.baseURL+'notification/read.php?user_id=' + this.userId+'&user_type='+this.userType+'&type=notification_count').subscribe((res: any) => {
      this.numberOfNotificationCount = res.records.numberOfNotification;
    });
  }

  getNotification(){ 
    this.http.get(environment.baseURL+'notification/read.php?user_id=' + this.userId+'&user_type='+this.userType+'&type=notification_list').subscribe((res: any) => {
      this.notificationItems = (res.records)?res.records:[];
    });
  }

  gotoNotification(item) {
    this.updateSeenStatus(item.id);
    this.router.navigateByUrl(`/order-details/${item.order_id}`);
  }

  viewAllNotification(){
    this.seenAllNotification();
    this.router.navigate(['/notification-list']);
  }

  seenAllNotification(){
   const data = {
        "type":"clearAll",
        "user_type" : this.userType,
        "user_id" : this.userId,
    }
    this.http.post(environment.baseURL+'notification/update.php',data).subscribe((res:any) => {
      // this.getNotificationCount();
      // this.getNotification();
    })
  }

  updateSeenStatus(id:number){
    const data = {
      "id" : id,
      "user_id" : this.userId,
      "user_type" : this.userType,
      "type" : "seen"
    }
    this.http.post(environment.baseURL+'notification/update.php',data).subscribe((res: any) => {
      
    });
  }

}

